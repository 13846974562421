import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    VStack,
    Grid,
    GridItem,
    useToast,
    Box, FormErrorMessage, useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useUserProfile } from '../../../providers/UserProfileProvider';
import { API } from '../../../utils/api';
import utils from '../../../utils/commonFunctions';
import { fetchAllAddresses } from "../../Address/AddressAPI";
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";

const useAddresses = () => {
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userProfile } = useUserProfile();

    useEffect(() => {
        const fetchAddresses = async () => {
            if (!userProfile || !userProfile._id) {
                setLoading(false);
                return;
            }
            try {
                const response = await fetchAllAddresses(1, 100, { userId: userProfile._id });
                if (response && response.addresses) {
                    setAddresses(response.addresses.map(addr => ({
                        ...addr,
                        formattedAddress: `
                            ${addr.addressType || ''} ${addr.streetAddress || ''} ${addr.cityTown || ''} 
                            ${addr.complexName || ''} ${addr.unitNumber || ''}`.replace(/\s+/g, ' ').trim()
                    })));
                } else {
                    throw new Error("No addresses fetched");
                }
            } catch (error) {
                console.error("Error fetching addresses:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchAddresses();
    }, [userProfile?._id]);

    return { addresses, loading };
};

const OrderFormDetails = () => {
    const { userProfile } = useUserProfile();
    const { addresses, loading } = useAddresses();
    const toast = useToast();
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleFileUpload = async (file, setFieldValue) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await API.post('/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.data && response.data.data.fileUrl) {
                const fileUrl = `${process.env.REACT_APP_API_BASE_URL.replace('/api/v1', '')}${response.data.data.fileUrl}`;
                setFileUrl(fileUrl);
                setFileName(response.data.data.fileName);
                setFieldValue("invoice", fileUrl);
                return fileUrl;
            } else {
                throw new Error('File upload did not return a file URL.');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    const handleFileRemove = async (setFieldValue) => {
        try {
            await API.post('/file/remove', { fileName });
            setFileUrl('');
            setFileName('');
            setFieldValue('invoice', '');
            document.getElementById("fileInput").value = '';
            toast({
                description: 'The Uploaded Invoice was removed.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error removing Invoice:', error);
            toast({
                description: 'Failed to remove Invoice.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            let userId = await utils.getUserId();
            const orderData = {
                ...values,
                userId
            };
            const response = await API.post('/orders/add', orderData);

            // Reset the form and clear file input
            resetForm({
                values: {
                    userId: userProfile?._id,
                    fullName: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
                    phoneNumber: userProfile ? userProfile.phone : '',
                    email: userProfile ? userProfile.email : '',
                    address: '',
                    addressDetails: '',
                    additionalRequests: '',
                    date: new Date().toISOString().substr(0, 10),
                    invoice: '',
                },
            });
            setFileUrl(''); // Clear file URL state
            setFileName(''); // Clear file name state
            document.getElementById("fileInput").value = ''; // Clear the file input field

            toast({
                title: 'Success',
                description: 'Order submitted successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast({
                title: 'Error',
                description: error?.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box
            p={"12"}
            borderRadius="lg"
            bg={"white"}
        >
            <Formik
                initialValues={{
                    userId: userProfile?._id,
                    fullName: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
                    phoneNumber: userProfile ? userProfile.phone : '',
                    email: userProfile ? userProfile.email : '',
                    address: '',
                    addressDetails: '',
                    additionalRequests: '',
                    date: new Date().toISOString().substr(0, 10),
                    invoice: '',
                }}
                validationSchema={Yup.object({
                    fullName: Yup.string().required('Full name is required.'),
                    phoneNumber: Yup.string()
                        .matches(/^[0-9]+$/, 'Phone number must contain only digits')
                        .min(7, 'Phone number must be at least 7 digits')
                        .max(15, 'Phone number must not exceed 15 digits')
                        .required('Phone number is required.'),
                    email: Yup.string().email('Invalid email address.').required('Email is required.'),
                    address: Yup.string().required('Address is required.'),
                    addressDetails: Yup.string().required('Address details are required.'),
                    additionalRequests: Yup.string(),
                    date: Yup.date().required('Date is required.'),
                    invoice: Yup.string().required('You must upload an invoice.'),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, setFieldValue, getFieldProps, values, isSubmitting, resetForm }) => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
                                <GridItem colSpan={1}>
                                    <Field name="fullName">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.fullName && form.touched.fullName}>
                                                <FormLabel>Full Name</FormLabel>
                                                <Input {...field} placeholder="Enter your full name" />
                                                <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="phoneNumber">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
                                                <FormLabel>Phone Number</FormLabel>
                                                <Input {...field} placeholder="Enter your phone number" />
                                                <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="email">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel>Email</FormLabel>
                                                <Input {...field} placeholder="Enter your email" />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="date">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.date && form.touched.date}>
                                                <FormLabel>Date</FormLabel>
                                                <Input {...field} type="date" />
                                                <FormErrorMessage>{form.errors.date}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl isInvalid={errors.addressDetails && touched.addressDetails}>
                                        <FormLabel>Address</FormLabel>
                                        <Select placeholder="Select address" onChange={e => {
                                            const selectedAddress = addresses.find(addr => addr._id === e.target.value);
                                            setFieldValue('address', e.target.value);
                                            setFieldValue('addressDetails', selectedAddress ? selectedAddress.formattedAddress : '');
                                        }}>
                                            {addresses.map(addr => (
                                                <option key={addr._id} value={addr._id}>
                                                    {addr.formattedAddress}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{errors.addressDetails}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl isInvalid={errors.addressDetails && touched.addressDetails}>
                                        <FormLabel>Selected Address Details</FormLabel>
                                        <Textarea
                                            {...getFieldProps('addressDetails')}
                                            placeholder="Detailed address information"
                                            color="gray.500"
                                            _focus={{
                                                borderColor: 'brand.500',
                                                boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                            }}
                                            _focusVisible={{
                                                borderColor: 'brand.500',
                                                boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                            }}
                                            _focusWithin={{
                                                borderColor: 'brand.500',
                                                boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                            }}
                                            isReadOnly
                                        />
                                        <FormErrorMessage>{errors.addressDetails}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>

                                <GridItem colSpan={1}>
                                    <Field name="additionalRequests">
                                        {({ field, form }) => (
                                            <FormControl>
                                                <FormLabel>Additional Requests</FormLabel>
                                                <Textarea
                                                    {...field}
                                                    placeholder="Any additional requests or notes"
                                                    color="gray.500"
                                                    _focus={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                    _focusVisible={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                    _focusWithin={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                />

                                                <FormErrorMessage>{form.errors.additionalRequests}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="invoice">
                                        {({ form }) => (
                                            <FormControl isInvalid={form.errors.invoice && form.touched.invoice}>
                                                <FormLabel>Invoice Upload</FormLabel>
                                                <Input
                                                    id="fileInput"
                                                    type="file"
                                                    onChange={async (event) => {
                                                        const file = event.currentTarget.files[0];
                                                        if (file) {
                                                            form.setFieldValue("invoice", "");
                                                            try {
                                                                await handleFileUpload(file, setFieldValue);
                                                            } catch (uploadError) {
                                                                console.error('File upload failed:', uploadError);
                                                                toast({
                                                                    title: 'Upload Error',
                                                                    description: 'Failed to upload file',
                                                                    status: 'error',
                                                                    duration: 5000,
                                                                    isClosable: true,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.invoice}</FormErrorMessage>
                                                {fileUrl && (
                                                    <Box mt={2}>
                                                        <Button
                                                            onClick={onOpen}
                                                            colorScheme="green"
                                                            size="sm"
                                                        >
                                                            Preview Invoice
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleFileRemove(setFieldValue)}
                                                            colorScheme="red"
                                                            size="sm"
                                                            ml={3}
                                                        >
                                                            Remove Invoice and Upload again
                                                        </Button>
                                                    </Box>
                                                )}
                                            </FormControl>
                                        )}
                                    </Field>

                                </GridItem>
                            </Grid>
                            <Button type="submit" colorScheme="green" isLoading={isSubmitting}>Submit Order</Button>
                        </VStack>
                        <PdfViewerModal filePath={fileUrl} isOpen={isOpen} onClose={onClose} />
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default OrderFormDetails;
