import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormControl, Input, Stack } from '@chakra-ui/react';
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { CustomButton } from "../../../components/Styled/StyledButtons";
import FieldErrorMessage from "../../../components/FieldErrorMessage/FieldErrorMessage";
import Icon from "../../../@core/components/icon";

// Define validation schema for the form fields
const validationSchema = Yup.object({
    subject: Yup.string().trim('The subject cannot be just whitespace').strict(true),
    startDate: Yup.date().nullable(),
    endDate: Yup.date()
        .nullable()
        .min(Yup.ref('startDate'), 'End date can’t be before start date'),
});

const NotificationSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    return (
        <Formik
            initialValues={{ subject: '', startDate: '', endDate: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                onFilterChange(values);
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack
                        spacing={2}
                        direction={{ base: 'column', md: 'row' }} // Stack items vertically on small screens, horizontally on medium and up
                        align="center"
                        width="100%"
                    >
                        <FormControl flex="1">
                            <Field
                                as={Input}
                                id="subject"
                                name="subject"
                                type="text"
                                placeholder="Search by Subject"
                            />
                            <FieldErrorMessage name="subject" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={Input}
                                id="startDate"
                                name="startDate"
                                type="date"
                            />
                            <FieldErrorMessage name="startDate" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={Input}
                                id="endDate"
                                name="endDate"
                                type="date"
                            />
                            <FieldErrorMessage name="endDate" />
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                            width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on medium and up
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();  // Reset the form fields
                                onFilterChange({ subject: '', startDate: '', endDate: '' }); // Clear the filter
                            }}
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                            width={{ base: '100%', md: 'auto' }}
                            tooltipLabel="Clear Filter"
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default NotificationSearchFilter;
