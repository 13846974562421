import {
  Flex,
  Stack,
  Icon,
  Text,
  Box,
  Heading,
  useToast,
  VStack,
  HStack,
  PinInput,
  PinInputField,
  Button,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomGeneralToast,
  CustomThemedToast,
} from "../../../utils/customChakraToasts";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import Footer from "../../../components/Footer/PortalFooter/Footer";
import {API} from "../../../utils/api";
import utils from "../../../utils/commonFunctions";
import enums from "wuc-library/enums";

function VerifyAccount() {
  const [otp, setOTP] = useState("");
  const [token, setToken] = useState(localStorage.getItem('userToken'));
  const [email, setEmail] = useState(utils.getUserEmail(token) || localStorage.getItem('registerEmail'));
  const [loading, setLoading] = useState(false);
  const { addGeneralToast } = CustomGeneralToast();
  const { addThemedToast } = CustomThemedToast();
  const toast = useToast();
  const [otpTimer, setOtpTimer] = useState(
      parseInt(localStorage.getItem('otpTimer'), 10) || 300
  );

  const navigate = useNavigate();

  useEffect(() => {
    try {
      handleVerifyAccount();
    } catch (error) {
      
      console.error(error);
    }
  }, [email, otp]);

  useEffect(() => {
    if (loading) {
      toast.closeAll();
      addThemedToast({
        message: "loading...",
        variant: "loading",
      });
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (otpTimer > 0) {
      const interval = setInterval(() => {
        setOtpTimer((prev) => {
          const newTime = prev - 1;
          localStorage.setItem('otpTimer', newTime);
          return newTime;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [otpTimer]);

  const handleVerifyAccount = async () => {
    if (!email || email === "") {
      toast.closeAll();
      addGeneralToast({
        message: "Account Verification failed. Please provide a valid email.",
        type: "error",
      });
      return;
    }
    if (!otp || otp === "") {
      toast.closeAll();
      addGeneralToast({
        message: "Please enter the OTP sent to your email.",
        type: "error",
      });
      return;
    }
    const data = { otpCode: otp, email: email };

    setLoading(true);
    try {
      const response = await API.post(
          `/auth/otp/validate`,
          data, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

      if (response.status === 200) {
        const data = response.data;
        toast.closeAll();
        addGeneralToast({
          message: data.message,
          type: "success",
        });
        localStorage.removeItem('registerEmail');
        localStorage.removeItem('otpTimer');
        navigate("/login");
      } else {
        throw new Error(response.data.message || "Account verification failed.");
      }
    } catch (error) {
      toast.closeAll();
      let errorMessage = "An unexpected error occurred while verifying your account.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      addGeneralToast({
        message: errorMessage,
        type: "error",
      });
      if (error.response) {
        utils.sendMessageToBugNinja('ERROR VERIFYING ACCOUNT', errorMessage);
      }
    }

    setLoading(false);
  };


  const requestNewOTP = async () => {
    if (!email || email === "") {
      toast.closeAll();
      addGeneralToast({
        message: "Account Verification failed. Invalid email",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await utils.requestNewOTP(email, enums.OTPUsage.REGISTRATION);

      if (response.status === 201) {
        const data = response.data;
        toast.closeAll();
        addGeneralToast({
          message: data.message,
          type: "success",
        });
        setOtpTimer(300);
        localStorage.setItem('otpTimer', 300);
      } else {
        toast.closeAll();
        addGeneralToast({
          message: response.data.message,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error verifying account:", error.response.data);
      toast.closeAll();
      addGeneralToast({
        message: error.response.data.message,
        type: "error",
      });
      utils.sendMessageToBugNinja('ERROR VERIFYING ACCOUNT', error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Stack
        maxW={{base:'full',md:'xl'}}
        minW={{base:'full',md:'xl'}}
        bg={"white"}
        p={8}
        borderRadius={"xl"}
        boxShadow={"md"}
        direction={{ base: "column", md: "row" }}
      >
          <Stack spacing={{base:2,sm:4}} w={"full"} >
            <Flex align={"center"} justify="center">
              <VStack>
              <Box
                backgroundColor="brand.500"
                borderRadius="50%"
                width="50px"
                height="50px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                  <Icon as={RiVerifiedBadgeLine} color="white" boxSize="50%" />
                </Box>
                <Heading color="black" as="h6" size="lg">
                  Account Verification
                </Heading>
                <Text color={"black"} mt={-1} fontSize={"lg"}>
                  We have sent you OTP to your email.
                </Text>
              </VStack>
            </Flex>
            <Flex justifyContent={"center"} my="4" gap={{base:2,sm:6}}>
              <PinInput type="alphanumeric" onComplete={(e) => setOTP(e)}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </Flex>

            <Stack spacing={0}>
              <Box>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                  Your OTP expires in {otpTimer > 0 ? ` ${formatTime(otpTimer)} ` : ' 00:00'}
                </Text>
              </Box>
              <HStack spacing={40} justifyContent="center" alignItems="center">

                <Box
                  color="black"
                  textAlign={"center"}
                  _hover={{ textDecoration: "none" }}
                >
                  <Text mb={0}> Didn't recieve the code?</Text>
                  <Button
                    onClick={requestNewOTP}
                    fontWeight={"semibold"}
                    bg={"white"}
                    color="brand.500"
                    _hover={{ bg: "white" }}
                  >
                    Resend OTP
                  </Button>
                </Box>
              </HStack>
            </Stack>
          </Stack>
      </Stack>
    </>
  );
}

export default VerifyAccount;
