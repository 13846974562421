import { useToast } from '@chakra-ui/react';

export const CustomGeneralToast = () => {
  const toast = useToast();

  const addGeneralToast = (newRes) => {
    toast({
      description: newRes.message,
      status: newRes.type,
      position: 'bottom',
      isClosable: true,
      duration: 5000,
    });
  };

  return { addGeneralToast };
};

export const CustomThemedToast = () => {
  const toast = useToast();

  const addThemedToast = (newRes) => {
    toast({
      description: newRes.message,
      variant: newRes.variant,
      position: 'bottom',
      isClosable: true,
      duration: 5000,
    });
  };

  return { addThemedToast };
};
