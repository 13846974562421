import React from "react";
import DashboardHeader from "../../components/Header/Portal/DashboardHeader";
import { Box, Container } from "@chakra-ui/react";
import Footer from "../Footer/PortalFooter/Footer";

const PortalContainer = ({ childComponent }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            minHeight="100vh"
            height={{ base: "100vh", md: "auto" }}
            position={{ base: "fixed", md: "relative" }}
            width="100%"
            p={{ base: 4, md: 0 }}  // Increased padding for small screens
        >
            <Box position="relative" zIndex="1">
                <DashboardHeader />
            </Box>
            <Container
                display="flex"
                flexDirection="column"
                flex="1"
                maxW="8xl"
                px={{ base: 4, md: 8 }}
                overflowY={{ base: "auto", md: "visible" }}
            >
                <Box flex="1" py={10} display="flex" flexDirection="column" justifyContent="center">
                    {childComponent}
                </Box>
            </Container>
            <Box position="relative" mt="auto" width="100%">
                <Footer />
            </Box>
        </Box>
    );
};

export default PortalContainer;