import React, { useEffect, useState, useCallback } from 'react';
import {Box} from "@chakra-ui/react";
import AddressTableTopBar from "./AddressTableTopBar";
import AddressTable from "./AddressTable";
import {fetchAllAddresses} from "../AddressAPI";

const AddressContainer = () => {
    const [addresses, setAddresses] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchAddressData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllAddresses(pageNumber, 5, filterOptions);
            setAddresses(data.addresses);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setAddresses([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, setTotalPages]);


    useEffect(() => {
        refetchAddressData();
    }, [fetchTrigger, refetchAddressData, refreshFlag, totalPages, filterOptions]);

    if (!addresses) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="gray.100">
                <p>Service is down...</p>
            </Box>
        );
    }

    return (
        <>
            <AddressTableTopBar
                refetchAddressData={refetchAddressData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <AddressTable
                addresses={addresses}
                setAddresses={setAddresses}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchAddressData={refetchAddressData}
            />
        </>
    );
};

export default AddressContainer;
