import React, { useCallback } from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import {StyledGrid} from "../../../components/Styled/StyledComponents";
import AddressSearchFilter from "./AddressSearchFilter";
import {ButtonStack, CustomButton} from "../../../components/Styled/StyledButtons";
import AddressAddForm from "./AddressAddForm";
import {useDrawer} from "../../../Context/DrawerContext/DrawerContext";
import Icon from "../../../@core/components/icon";

const AddressTableTopBar = ({ refetchAddressData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);



    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 12, md: 11 }}>
                    <AddressSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Address"
                            icon={<Icon icon="tdesign:grid-add" />}
                            onClick={() => openDrawer({
                                title: 'Add New Address',
                                component: AddressAddForm,
                                props: { refetchAddressData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default AddressTableTopBar;
