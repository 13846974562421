import React from 'react';
import {
    VStack, Box, Text, Badge, SimpleGrid
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";

const AddressViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { addressType, streetAddress, cityTown, complexName, unitNumber } = formData;

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Address"/>
            <SimpleGrid columns={1} spacing={4} px={2} py={4}>
                <SectionHeading icon="fluent:apps-list-detail-24-filled" text="Address Information" />
                <Box>
                    <Text>
                        <strong>Address Type: </strong>
                        <Badge variant="outline" colorScheme="green">{addressType}</Badge>
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Street Address: </strong>{streetAddress}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>City/Town: </strong>{cityTown}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Complex Name: </strong>{complexName || 'N/A'}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Unit Number: </strong>{unitNumber || 'N/A'}
                    </Text>
                </Box>
                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <CustomAlert status="info" message="This is a view-only mode. No changes can be made here." />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </SimpleGrid>
        </VStack>
    );
};

export default AddressViewForm;
