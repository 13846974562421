import React, { useCallback } from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import {StyledGrid} from "../../../components/Styled/StyledComponents";
import NotificationSearchFilter from "./NotificationSearchFilter";
import {ButtonStack, CustomButton} from "../../../components/Styled/StyledButtons";
import {useDrawer} from "../../../Context/DrawerContext/DrawerContext";
import Icon from "../../../@core/components/icon";

const NotificationTableTopBar = ({ refetchNotificationData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);  // Toggle the fetch trigger to re-fetch data
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 12, md: 12 }}>
                    <NotificationSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default NotificationTableTopBar;
