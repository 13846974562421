import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormControl, Input, Select, Stack } from '@chakra-ui/react';
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { CustomButton } from "../../../components/Styled/StyledButtons";
import FieldErrorMessage from "../../../components/FieldErrorMessage/FieldErrorMessage";
import Icon from "../../../@core/components/icon";

// Define validation schema for the form fields
const validationSchema = Yup.object({
    streetAddress: Yup.string().trim('The street address cannot be just whitespace').strict(true),
    cityTown: Yup.string().trim('The city/town cannot be just whitespace').strict(true),
    addressType: Yup.string(),
});

const AddressSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    const addressTypeOptions = ['Residential', 'Business', 'Other'];

    return (
        <Formik
            initialValues={{ streetAddress: '', cityTown: '', addressType: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                onFilterChange(values);
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack
                        spacing={2}
                        direction={{ base: 'column', md: 'row' }} // Stack items vertically on small screens, horizontally on medium and up
                        align="center"
                        width="100%"
                    >
                        <FormControl flex={{ base: '1', md: '2' }}>
                            <Field
                                as={Input}
                                id="streetAddress"
                                name="streetAddress"
                                type="text"
                                placeholder="Search by Street Address"
                            />
                            <FieldErrorMessage name="streetAddress" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field
                                as={Input}
                                id="cityTown"
                                name="cityTown"
                                type="text"
                                placeholder="Filter by City/Town"
                            />
                            <FieldErrorMessage name="cityTown" />
                        </FormControl>
                        <FormControl flex="1">
                            <Field as={Select} name="addressType" placeholder="Select Address Type">
                                {addressTypeOptions.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </Field>
                        </FormControl>
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                            width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on medium and up
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ streetAddress: '', cityTown: '', addressType: '' });
                            }}
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                            width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on medium and up
                            tooltipLabel="clear filter"
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default AddressSearchFilter;
