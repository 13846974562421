import React, {useState, createContext, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const value = { redirectUrl, setRedirectUrl };
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useRedirectUrl= () => useContext(AuthContext);