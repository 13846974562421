import React, { createContext, useState, useContext } from 'react';

// Create the context
const PhotoContext = createContext();

// Create a provider component
export const PhotoProvider = ({ children }) => {
    const [photo, setPhoto] = useState(0);

    return (
        <PhotoContext.Provider value={{ photo, setPhoto }}>
            {children}
        </PhotoContext.Provider>
    );
};

// Custom hook to access the context
export const usePhoto = () => useContext(PhotoContext);
