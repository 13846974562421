import React, {useEffect, useState} from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  Spacer,
  Stack,
  StackDivider,
  Text,
  VStack

} from "@chakra-ui/react";
import EMARKETLogo from "./../../../assets/emarket-favicon.png";


import { MdEdit} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import {Link} from "react-router-dom";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import enums from "wuc-library/enums";
import utils from "../../../utils/commonFunctions";

const ViewProfile = () => {
  const {userProfile}=useUserProfile()
  const {firstName,lastName,email}=userProfile?.personalDetails||{}
  const [user,setUser]=useState(null)

  //let fullname=(firstName && lastName)?`${firstName} ${lastName}`:utils.getUser()?.name
  //let userEmail=(email)?email:utils.getUser()?.email
  let fullname = (firstName && lastName) ? `${firstName} ${lastName}` : `${user?.firstName} ${user?.lastName}`;
  let userEmail = email || user?.email;
  useEffect(() => {
    const getUser =async ()=>{
      setUser(await utils.fetchUser())
    }
    setUser(getUser())
  }, []);
  return (
    <>
      <VStack
        mt="2"
        minH={["699px"]}
        align={"start"}
        justifyContent={"start"}
        color="black"
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={6}
          w={"100%"}
          justify={"center"}
        >
          <GridItem colSpan={12} p={"12"} borderRadius="lg" bg={"white"}>
            <HStack alignItems="center">
              <Avatar
                alignSelf="center"
                justify={"center"}
                size="lg"
                name={fullname}
              />
              <Box verticalAlign={"center"}>
                <Heading
                  color="black"
                  as="h6"
                  fontWeight={"normal"}
                  size="md"
                >
                  {fullname}
                  <Badge
                    borderRadius={"xl"}
                    py={0.5}
                    px={2}
                    ml="2"
                    colorScheme={user?.isVerified?"green":"red"}
                  >
                    {user?.isVerified?"Verified Account":"Account Not Verified"}

                  </Badge>
                </Heading>
                <Text fontSize={"sm"}>{userEmail}</Text>
              </Box>
              <Spacer />
              <Link to={'/customer-portal/update-profile'}>
                <Button leftIcon={MdEdit()}>Update Profile</Button>
              </Link>

            </HStack>
            <GridItem colSpan={6} bg={"white"}>
              <Box>
                <Heading
                  color="black"
                  as="h6"
                  fontWeight="semibold"
                  size="md"
                  my={4}
                  pb={2}
                >
                  Profile Details
                </Heading>
              </Box>
               <Grid templateColumns="repeat(12, 1fr)" gap={8}>
                <GridItem colSpan={6}>
                  <Box>
                    <Heading
                      color="black"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      First Name
                    </Heading>
                    <Text textTransform={'capitalize'} fontSize="sm">{firstName||user?.firstName} </Text>
                  </Box>
                </GridItem>

                <GridItem colSpan={6}>
                  <Box>
                    <Heading
                      color="black"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Last Name
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{lastName||user?.lastName} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={6}>
                  <Box>
                    <Heading
                      color="black"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Contact Number
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{user?.phone} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={6}>
                  <Box>
                    <Heading
                      color="black"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Alternate Number
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{user?.alternativeMobileNumber} </Text>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
          </GridItem>

        </Grid>



        <Box>
          <VStack
            p={"4"}
            align={"start"}
            bg={"gray.50"}
            borderBottom="1px solid "
            borderColor={"gray.200"}
            borderTopRadius="lg"
          >
            <Box>
              <Heading
                color="black"
                as="h6"
                fontWeight={"medium"}
                size="md"
                mb={1}
              >
                Security
              </Heading>
              <Text fontSize={"md"} fontWeight={"thin"}>
                Update your password to make your account more secure.
              </Text>
            </Box>
          </VStack>

          <Grid
            bg={"white"}
            px={"12"}
            py={"8"}
            borderBottomRadius="lg"
            templateColumns="repeat(12, 1fr)"
            gap={6}
            w={"100%"}
            alignItems="center"
            justify={"center"}
          >
            <GridItem colSpan={11}>
              <HStack alignItems="center">
                <Icon
                  as={RiLockPasswordFill}
                  boxSize={"64px"}
                  color="white"
                  p={4}
                  bg={"brand.500"}
                  borderRadius={"50%"}
                />
                <Box verticalAlign={"center"}>
                  <Heading
                    color="black"
                    as="h6"
                    fontWeight={"normal"}
                    size="md"
                  >
                    Password
                  </Heading>
                </Box>
              </HStack>
            </GridItem>
            <GridItem colSpan={1}>
              <Link to={'/customer-portal/change-password'}>
                <Button
                        _hover={{ bg: "blue.400", color: "white" }}
                        colorScheme="black"
                        leftIcon={<MdEdit />}
                        bg={"brand.500"}
                >
                  Change Password
                </Button>
              </Link>

            </GridItem>
          </Grid>
        </Box>
      </VStack>
    </>
  );
};

export default ViewProfile;
