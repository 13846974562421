import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import OrderFormDetails from "./Components/OrderFormDetails";


const OrderForm = () => {
    return <>
        <PortalContainer Title={"Order Form"} childComponent={ <OrderFormDetails/>}/>



    </>
};

export default OrderForm;
