import React from 'react';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading} from '@chakra-ui/react';
import {useLocation} from 'react-router-dom';
import {RxSlash} from "react-icons/rx";

const CustomBreadcrumb = ({base,color="black"}) => {
    const location = useLocation();

    // Function to convert URL path to human-readable text
    const formatText = (text) => {
        return text
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // Function to determine the current page based on the route
    const getCurrentPage = (path) => {
        const routes = {
            dashboard: 'Dashboard',
            'new-connection': 'New Connection',
            'pay-bill-online': 'Pay Bill Online',
            'view-account-balance': 'View Account Balance',
            'submit-meter-reading': 'Submit Meter Reading',
            'request-monthly-bill': 'Request Monthly Bill',
            'report-leak': 'Report Leak',
            'manage-utility-contracts': 'Manage Utility Contracts',
            'manage-beneficiary': 'Manage Beneficiary',
            'know-your-customer': 'Know Your Notification',
            'update-profile': 'Update Profile',
            'change-password': 'Change Password',
        };
        const lastSegment = path.split('/').pop();
        return routes[lastSegment] || formatText(lastSegment);
    };

    const currentPage = getCurrentPage(location.pathname);

    return (
        <>
            <Heading size={'lg'} color={color} >{currentPage}</Heading>
            <Breadcrumb
                spacing='1px'
                separator={<RxSlash boxSize={['6', '6']} color={base==='self-service-portal'?'brand.500':color}/>}
                borderRadius={'md'}
            >
                <BreadcrumbItem>
                    <BreadcrumbLink
                        href='#'
                        style={{textDecoration: 'none'}}
                        fontSize={['sm']}
                        fontWeight='normal'
                        color={color}
                    >
                        {base}
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                        href='#'
                        fontSize={['sm']}
                        fontWeight='normal'
                        color={color}
                    >
                        {currentPage}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </>
    );
};

export default CustomBreadcrumb;
