import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Spinner,
} from '@chakra-ui/react';
import NotificationViewForm from "./NotificationViewForm";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { ViewButton } from "../../../components/Styled/ActionButtons";
import { fetchNotificationById, fetchAllNotifications } from "../NotificationAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";

const NotificationTable = ({ notifications, setNotifications, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const handleView = async (notification) => {
        try {
            const notificationData = await fetchNotificationById(notification._id);
            if (notificationData) {
                openDrawer({
                    title: 'View Notification',
                    component: NotificationViewForm,
                    props: { formData: notificationData },
                    key: `view-${notification._id}`,
                }, 'lg');
            } else {
                throw new Error('Notification data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const truncateText = (text, maxLength = 30) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const refetchNotificationData = useCallback(async (options = {}) => {
        try {
            setLoading(true); // Set loading to true before fetching data
            const data = await fetchAllNotifications(pageNumber, 5, { ...filterOptions, kind: 'CustomerNotification' });
            setNotifications(data.notifications);
            setTotalPages(Math.ceil(data.totalDocs / 5));
        } catch (error) {
            setError(error.message || "An error occurred.");
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    }, [filterOptions, pageNumber, setNotifications, setTotalPages]);

    useEffect(() => {
        refetchNotificationData(filterOptions);
    }, [pageNumber, refetchNotificationData, filterOptions, setRefreshFlag]);

    const formatHtmlContent = (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        return doc.body.textContent || "";
    };

    if (loading) {
        return (
            <Box textAlign="center" my="4" p="4">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100"><p>Error fetching Notification data:</p><code>{error.toString()}</code></Box>;
    }

    if (dataLoaded && Array.isArray(notifications) && notifications.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Type</Th>
                            <Th>Subject</Th>
                            <Th>Message</Th>
                            <Th>Date</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {notifications.map((notification, index) => (
                            <Tr key={index}>
                                <Td>{notification.type}</Td>
                                <Td>{notification.subject || 'N/A'}</Td>
                                <Td>{truncateText(formatHtmlContent(notification.message)) || 'N/A'}</Td>
                                <Td>{new Date(notification.createdDate).toLocaleDateString()}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(notification)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog isOpen={confirmDialog.isOpen} title={confirmDialog.title} message={confirmDialog.message} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} onConfirm={confirmDialog.onConfirm} />
        </>
    );
};

export default NotificationTable;
