/*import utils from "../../utils/commonFunctions";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useRedirectUrl} from "../../providers/AuthProvider";
function PrivateRoute() {
    const { setRedirectUrl } = useRedirectUrl()||{};
    const navigate=useNavigate()
    const location = useLocation();
    if (utils.isAuthenticated()) {
      return <Outlet />
    }
    setRedirectUrl(location.pathname)
    utils.logout();
    navigate('/login')
}


export default PrivateRoute;*/

import React, { useEffect, useState } from 'react';
import utils from "../../utils/commonFunctions";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import { useDrawer } from "../../Context/DrawerContext/DrawerContext";
import { useRedirectUrl } from "../../providers/AuthProvider";
import {CustomGeneralToast} from "../../utils/customChakraToasts";

function PrivateRoute() {
    const { setRedirectUrl } = useRedirectUrl() || {};
    const navigate = useNavigate();
    const location = useLocation();
    const { closeDrawer } = useDrawer();
    const { addGeneralToast } = CustomGeneralToast();  // Using CustomGeneralToast
    const [isSessionExpired, setSessionExpired] = useState(false);
    const [hasNavigated, setHasNavigated] = useState(false);

    // Function to handle session expiration due to inactivity
    const handleInactivityExpiration = () => {
        if (!isSessionExpired) {
            closeDrawer();
            utils.logout(navigate, addGeneralToast, false);  // Log out the user
            addGeneralToast({
                message: "Your session has expired due to inactivity.",
                type: "warning",
            });
            setSessionExpired(true);  // Prevent multiple toasts
        }
    };

    // Idle timer to detect inactivity
    useIdleTimer({
        timeout: 1000 * 60 * 10,  // 10 minutes
        onIdle: handleInactivityExpiration,
        debounce: 500,
    });

    // Periodic authentication check to ensure session validity
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!utils.isAuthenticated()) {
                closeDrawer();
                utils.logout(navigate, addGeneralToast, false);
                navigate('/login', { replace: true });
                addGeneralToast({
                    message: "Your session has expired. Please log in again.",
                    type: "warning",
                });
            }
        }, 5000);  // Check every 5 seconds

        return () => clearInterval(intervalId);  // Cleanup interval on unmount
    }, [navigate, addGeneralToast, closeDrawer]);

    // Check if the user is authenticated on component mount
    useEffect(() => {
        if (!utils.isAuthenticated() && !hasNavigated) {
            closeDrawer();
            setRedirectUrl(location.pathname);
            utils.logout(navigate, addGeneralToast, false);  // Log out
            navigate('/login', { replace: true });  // Navigate to login page

            // Set navigation flag after initial load to prevent showing toast immediately
            setHasNavigated(true);
        }
    }, [hasNavigated, navigate, location, setRedirectUrl, closeDrawer, addGeneralToast]);

    // Render child routes if the user is authenticated
    if (utils.isAuthenticated()) {
        return <Outlet />;
    }

    return null;  // Return null when not authenticated to prevent rendering
}

export default PrivateRoute;



