import React from 'react';
import SetNewPassword  from '../../pages/ChangePassword/Components/ChangePassword';
import PortalContainer from "../../components/PortalContainer/PortalContainer";


const ChangePassword = () => {
  return <>
    <PortalContainer Title={"Change Password"} childComponent={ <SetNewPassword/>}/>


  
  </>
};

export default ChangePassword;
