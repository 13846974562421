import {API} from "../../utils/api";
import utils from "../../utils/commonFunctions";


export const createAddress = async (addressData) => {
    try {
        const response = await API.post('/address/add', addressData);
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error('Failed to create address with status: ' + response.status);
        }
    } catch (error) {
        throw new Error(error?.response?.data?.message || error?.message || "An unexpected error occurred while creating the Address.");
    }
};


export const fetchAllAddresses = async (pageNumber = 1, pageSize = 5, filterOptions = {}) => {
    try {
        let userId = await utils.getUserId();
        filterOptions.userId = userId;
        const data = {
            pageNumber,
            pageSize,
            filterOptions
        };
        const response = await API.post('/address/get', data);
        if (response.status === 200 && response.data) {
            return {
                addresses: response.data.data.docs,
                totalPages: Math.ceil(response.data.data.totalDocs / pageSize),
                totalDocs: response.data.data.totalDocs
            };
        }
        throw new Error(`Unexpected response status: ${response.status}`);
    } catch (error) {
        console.error("API Error:", error);
        throw new Error(error.message || "Failed to fetch Addresses.");
    }
};




// FetchAddress by ID using query parameters
export const fetchAddressById = async (addressId) => {
    try {
        const userId = await utils.getUserId();
        const filterOptions = {
            userId: userId,
            addressId: addressId
        };
        const data = {
            filterOptions: filterOptions,
            pageNumber: 1,
            pageSize: 1
        };

        const response = await API.post('/address/get', data);

        if (response.status === 200 && response.data && response.data.data && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Address not found.');
        }
    } catch (error) {
        console.error("Failed to fetch the Address details:", error);
        throw new Error(error?.message || "Failed to fetch the Address details.");
    }
};



// UpdateAddress
export const updateAddress = async (addressId, addressData) => {
    try {
        const response = await API.put(`/address/update/${addressId}`, addressData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updatingAddress:", error);
        throw new Error(error?.message || "An error occurred while updating the Address.");
    }
};


// DeleteAddress
export const deleteAddress = async (addressId) => {
    try {
        const response = await API.delete(`/address/delete/${addressId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the Address");
    }
};
