import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ChakraProvider} from "@chakra-ui/react";
import customTheme from "./theme/customTheme";
import {QuoteCartProvider} from "./providers/QuoteCartProvider";
import {NavBarProvider} from './providers/NavBarSliderProvider';
import {UserProfileProvider} from "./providers/UserProfileProvider";
import {PhotoProvider} from "./providers/PhotosProvider";
import {AuthProvider} from "./providers/AuthProvider";
import {ThemeProvider} from "styled-components";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
            <ChakraProvider theme={customTheme}>
                <ThemeProvider theme={customTheme}>
                    <AuthProvider>
                    <UserProfileProvider>
                        <NavBarProvider>
                            <QuoteCartProvider>
                                <PhotoProvider>
                                    <App/>
                                </PhotoProvider>
                            </QuoteCartProvider>
                        </NavBarProvider>
                    </UserProfileProvider>
                    </AuthProvider>
                </ThemeProvider>
            </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
