import React from 'react';
import {
    VStack, Box, Text, Badge, SimpleGrid
} from '@chakra-ui/react';
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import DOMPurify from 'dompurify';

const NotificationViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { type, subject, message, createdDate, editedDate, createdBy } = formData;

    const sanitizeHtmlContent = (content) => {
        // Sanitize the HTML to prevent XSS attacks and format it correctly
        return { __html: DOMPurify.sanitize(content) };
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Notification"/>
            <SimpleGrid columns={1} spacing={4} px={2} py={4}>
                <SectionHeading icon="fluent:apps-list-detail-24-filled" text="Notification Information" />
                <Box>
                    <Text>
                        <strong>Type: </strong>
                        <Badge variant="outline" colorScheme="green">{type}</Badge>
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Subject: </strong>{subject || 'N/A'}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Message: </strong>
                    </Text>
                    <Box
                        mt={2}
                        p={8}
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        dangerouslySetInnerHTML={sanitizeHtmlContent(message)}
                    />
                </Box>
                <Box>
                    <Text>
                        <strong>Notification Date: </strong>{new Date(createdDate).toLocaleDateString()}
                    </Text>
                </Box>
                {/*<Box>
                    <Text>
                        <strong>Edited Date: </strong>{new Date(editedDate).toLocaleDateString()}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <strong>Created By: </strong>{createdBy || 'N/A'}
                    </Text>
                </Box>*/}
                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <CustomAlert status="info" message="This is a view-only mode. No changes can be made here." />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </SimpleGrid>
        </VStack>
    );
};

export default NotificationViewForm;
