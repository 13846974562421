import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Heading,
    Flex,
    Grid,
    GridItem,
    Divider,
    Link,
    AccordionPanel,
    Accordion,
    AccordionItem,
    AccordionButton,
    Spinner,
} from "@chakra-ui/react";
import { API } from "../../../utils/api";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Icon } from "@iconify/react";
import whatsappIcon from '@iconify-icons/mdi/whatsapp';
import phoneIcon from '@iconify-icons/mdi/phone';
import emailIcon from '@iconify-icons/mdi/email-outline';

const FAQAccordion = ({ FAQs }) => {
    return (
        <Box w="full">
            <Accordion allowToggle w="full" borderWidth={0} borderRadius="lg">
                {FAQs.map((faq, index) => (
                    <AccordionItem
                        key={index}
                        w="full"
                        borderWidth={0}
                        borderColor="white"
                        borderRadius="lg"
                        mb={4}
                        color="black"
                    >
                        {({ isExpanded }) => (
                            <Box boxShadow={isExpanded && "xl"}>
                                <AccordionButton
                                    w="full"
                                    p={4}
                                    m={0}
                                    borderRadius="lg"
                                    fontWeight="semibold"
                                    fontSize={{ base: "sm", md: "md" }}  // Adjust font size based on screen size
                                    bg={isExpanded ? "white" : "gray.100"}
                                    color={isExpanded ? "brand.500" : "black"}  // Title color changes based on active/inactive state
                                    _hover={isExpanded && { bg: "white" }}
                                >
                                    <Grid templateColumns="repeat(12, 1fr)" w="full" gap={2}>
                                        <GridItem
                                            colSpan={{ base: 1, md: 1 }}
                                            textAlign="center"
                                        >
                                            <Flex
                                                justifyContent="center"
                                                align="center"
                                                h="full"
                                                w="full"
                                            >
                                                {index + 1 < 10
                                                    ? `0${index + 1}`
                                                    : index + 1}
                                            </Flex>
                                        </GridItem>
                                        <GridItem colSpan={{ base: 10, md: 10 }}>
                                            <Flex
                                                h="full"
                                                w="full"
                                                textAlign="start"
                                                justify="start"
                                                align="center"
                                                pl={{ base: 2, md: 0 }}  // Added padding on the left for mobile view
                                            >
                                                {faq.question}
                                            </Flex>
                                        </GridItem>
                                        <GridItem
                                            colSpan={{ base: 1, md: 1 }}
                                            textAlign="center"
                                        >
                                            <Flex
                                                h="full"
                                                w="full"
                                                justify="center"
                                                align="center"
                                                bg={
                                                    isExpanded
                                                        ? "white"
                                                        : "gray.100"
                                                }
                                                p={2}
                                            >
                                                {isExpanded ? (
                                                    <MinusIcon />
                                                ) : (
                                                    <AddIcon />
                                                )}
                                            </Flex>
                                        </GridItem>
                                    </Grid>
                                </AccordionButton>
                                <AccordionPanel
                                    textAlign="start"
                                    borderRadius="lg"
                                    px={{ base: 4, md: 8 }}
                                    pb={{ base: 2, md: 4 }}
                                    color="black"  // Content (answer) color remains black
                                    w="100%"  // Ensure content uses full width
                                >
                                    <Box>
                                        <Text fontSize={{ base: "sm", md: "md" }}>
                                            <strong>Answer: </strong>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.answer,
                                                }}
                                            />
                                        </Text>
                                    </Box>
                                </AccordionPanel>
                            </Box>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

const FAQDetails = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    const getFaqs = async () => {
        try {
            const response = await API.get(`/faqs`);
            const data = await response.data.docs;
            if (response.status === 200) {
                setFaqs(data);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    useEffect(() => {
        getFaqs();
    }, []);

    return (
        <>
            <Box
                p={4}
                mt={4}
                mb={4}
                borderRadius="lg"
                bg={"white"}
            >
                <Flex
                    justify="center"
                    align="center"
                    bg="white"
                    p={{ base: 4, md: 8 }}
                    w="full"
                    direction="column"
                >
                    {/* Video Section */}
                    <Grid
                        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                        gap={6}
                        w="full"
                        maxW="1200px"
                        mb={8}
                    >
                        <GridItem>
                            <Box position="relative">
                                <Box
                                    as="iframe"
                                    width="100%"
                                    height={{ base: "200px", md: "300px" }}  // Responsive video height
                                    src="https://www.youtube.com/embed/9Uea0QyIavY?si=C6Od6YFgmesta59P"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Navigating your profile"
                                />
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box>
                                <Heading size={{ base: "sm", md: "md" }} mb={2} color="brand.500">
                                    A step by step guide: Navigating your profile
                                </Heading>
                                <Text mb={4} color="gray.600" fontSize={{ base: "sm", md: "md" }}>
                                    Learn how to navigate your profile settings and
                                    customize your user experience.
                                </Text>
                            </Box>
                        </GridItem>
                    </Grid>

                    <Divider mb={2} />

                    {/* FAQs Section */}
                    <Box w="full">
                        <Heading
                            size={{ base: "md", md: "lg" }}  // Responsive heading size
                            mb={6}
                            mt={4}
                            textAlign="left"
                            color="brand.500"
                        >
                            FAQs
                        </Heading>
                        {loading ? (
                            <Flex justify="center" align="center" minHeight="200px">
                                <Spinner size="lg" />
                            </Flex>
                        ) : faqs.length === 0 ? (
                            <Flex
                                justify="center"
                                align="center"
                                mt={{ base: 0, lg: 16 }}
                            >
                                <Heading color="gray.500">
                                    No Record Found
                                </Heading>
                            </Flex>
                        ) : (
                            <FAQAccordion FAQs={faqs} />
                        )}
                    </Box>

                    {/* Help Section */}
                    <Box w="full" mb={8}>
                        <Heading
                            size={{ base: "sm", md: "md" }}
                            mt={4}
                            mb={4}
                            textAlign="left"
                            color="gray.500"
                        >
                            Can't find what you're looking for?
                        </Heading>
                        <Flex justify="flex-start" align="center" flexWrap="wrap" gap={{ base: 10, md: 10, lg: 40 }}>
                            <Flex direction="row" align="center" mb={4}>
                                <Link href="https://wa.me/26777740695" isExternal>
                                    <Flex justifyContent="center">
                                        <Icon icon={whatsappIcon} width="40" height="40" color="green" />
                                    </Flex>
                                </Link>
                                <Text mt={2} ml={2} fontSize={{ base: "sm", md: "md" }}>
                                    Get in touch with our<br />WhatsApp helpline
                                </Text>
                            </Flex>
                            <Flex direction="row" align="center" mb={4}>
                                <Link href="tel:+26777740695">
                                    <Flex justifyContent="center">
                                        <Icon icon={phoneIcon} width="40" height="40" />
                                    </Flex>
                                </Link>
                                <Text mt={2} ml={2} fontSize={{ base: "sm", md: "md" }}>
                                    Call us directly<br />+267 777-40695
                                </Text>
                            </Flex>
                            <Flex direction="row" align="center" mb={4}>
                                <Flex justifyContent="center">
                                    <Icon icon={emailIcon} width="40" height="40" />
                                </Flex>
                                <Text mt={2} ml={2} fontSize={{ base: "sm", md: "md" }}>
                                    Send us a message<br />directly from your account
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </>
    );
};

export default FAQDetails;
