import React, {useState, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {Box, Spinner} from "@chakra-ui/react";
import AppRoutes from "./Routes";
import {DrawerProvider} from "./Context/DrawerContext/DrawerContext";
import ReusableDrawer from "./Context/DrawerContext/ReusableDrawer";

function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Simulate a loading time
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // 3 seconds loading time

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="brand.500"
                    size="xl"
                />
            </Box>
        );
    }
    return (
        <DrawerProvider>
            <Router>
                <div className="App">
                    <ReusableDrawer />
                    <AppRoutes />
                </div>
            </Router>
        </DrawerProvider>
    );
}

export default App;
