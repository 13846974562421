import React, { createContext, useState, useContext } from 'react';

// Create the context
const QuoteCartContext = createContext();

// Create a provider component
export const QuoteCartProvider = ({ children }) => {
  const [quoteCart, setQuoteCart] = useState([]);
  const [customer, setCustomer] = useState({});
  const [billBalance, setBillBalance] = useState({});
  const [refreshFlag, setRefreshFlag] = useState(false);

  return (
    <QuoteCartContext.Provider value={{ quoteCart, setQuoteCart,customer,setCustomer,billBalance,setBillBalance,refreshFlag,setRefreshFlag }}>
      {children}
    </QuoteCartContext.Provider>
  );
};

// Custom hook to access the context
export const useQuoteCart = () => useContext(QuoteCartContext);
