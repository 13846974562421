import React from 'react';
import {
    FormControl, FormLabel, GridItem, VStack, Box, Text, InputGroup,
    Input, SimpleGrid, Spinner, Switch, Badge, Select, FormErrorMessage
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { createAddress } from "../AddressAPI";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import FormActionLabel from "../../../components/Styled/FormActionLabel";
import utils from "../../../utils/commonFunctions";

const AddressAddForm = ({ refetchAddressData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();

    const addressSchema = Yup.object().shape({
        addressType: Yup.string().oneOf(['Residential', 'Business', 'Other'], 'Invalid address type').required('Address type is required.'),
        streetAddress: Yup.string().required('Street address is required.'),
        cityTown: Yup.string().required('City/Town is required.'),
        complexName: Yup.string(),
        unitNumber: Yup.string()
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let userId = await utils.getUserId();
            const addressData = {
                ...values,
                userId
            };
            await createAddress(addressData);
            showToast({
                title: 'Address added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAddressData();
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error',
                description: error?.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <>
            <FormActionLabel formAction="add" formName="Address" />
            <Formik
                initialValues={{
                    addressType: '',
                    streetAddress: '',
                    cityTown: '',
                    complexName: '',
                    unitNumber: ''
                }}
                validationSchema={addressSchema}
                onSubmit={handleSubmit}
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <GridItem>
                                    <Field name="addressType">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.addressType && form.touched.addressType}>
                                                <FormLabel htmlFor="addressType">Address Type</FormLabel>
                                                <Select {...field} id="addressType" placeholder="Select Address Type">
                                                    {['Residential', 'Business', 'Other'].map(option => (
                                                        <option key={option} value={option}>{option}</option>
                                                    ))}
                                                </Select>
                                                <FormErrorMessage>{form.errors.addressType}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem>
                                    <Field name="streetAddress">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.streetAddress && form.touched.streetAddress}>
                                                <FormLabel htmlFor="streetAddress">Street Address</FormLabel>
                                                <Input {...field} id="streetAddress" placeholder="Enter Street Address" />
                                                <FormErrorMessage>{form.errors.streetAddress}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem>
                                    <Field name="cityTown">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.cityTown && form.touched.cityTown}>
                                                <FormLabel htmlFor="cityTown">City/Town</FormLabel>
                                                <Input {...field} id="cityTown" placeholder="Enter City/Town" />
                                                <FormErrorMessage>{form.errors.cityTown}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem>
                                    <Field name="complexName">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.complexName && form.touched.complexName}>
                                                <FormLabel htmlFor="complexName">Complex Name</FormLabel>
                                                <Input {...field} id="complexName" placeholder="Enter Complex Name" />
                                                <FormErrorMessage>{form.errors.complexName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem>
                                    <Field name="unitNumber">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.unitNumber && form.touched.unitNumber}>
                                                <FormLabel htmlFor="unitNumber">Unit Number</FormLabel>
                                                <Input {...field} id="unitNumber" placeholder="Enter Unit Number" />
                                                <FormErrorMessage>{form.errors.unitNumber}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                            </SimpleGrid>
                            <AlertAndButtons isSubmitting={formikProps.isSubmitting} closeDrawer={closeDrawer} />
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const AlertAndButtons = ({ isSubmitting, closeDrawer }) => (
    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
        <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
            <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                Cancel
            </CustomButton>
            <CustomButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Spinner size="xs" mr={2} /> : 'Submit'}
            </CustomButton>
        </ButtonStack>
    </Box>
);

export default AddressAddForm;
