import enums from "wuc-library/enums";
import DOMPurify from "dompurify";
import { API } from "./api";
import axios from "axios";
const CryptoJS = require('crypto-js');
const decodeJWT = (authToken) => {

    if (authToken) {
        // Decode the JWT token
        const tokenParts = authToken.split(".");
        const encodedPayload = tokenParts[1];
        const decodedPayload = JSON.parse(atob(encodedPayload));
        return decodedPayload;
    }
    return null;
};

const utils = {

    decodeToken : (authToken) => {

        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const decodedPayload = JSON.parse(atob(encodedPayload));
            return decodedPayload;
        }
        return null;
    },
    isAuthenticated: () => {


        const jwt = decodeJWT(localStorage.getItem("authToken"));
        if (jwt) {
            // Check if the token has expired
            const tokenExpiration = jwt.exp * 1000; // Convert expiration time to milliseconds
            const currentTimestamp = Date.now();
            if (currentTimestamp < tokenExpiration) {
                // Token valid
                return true;
            }
        }
        return false;
    },
    getIsVerified: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.isVerified;
            }
        }
        return false;
    },
    getIsFirstTimeLogin: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.isFirstTimeLogin;
            }
        }
        return false;
    },
    getRequirePasswordChange: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.requirePasswordChange;
            }
        }
        return false;
    },
    setToken: (name, token) => {
        localStorage.setItem(name, token);
    },
    getUserEmail: (token) => {
        let jwt = decodeJWT(token)
        if (jwt) {
            return jwt.email
        }
        return null;
    },
    getUserRole: (token) => {
        let jwt = decodeJWT(token)
        if (jwt) {
            return jwt.role
        }
        return null;
    },
    getUserId: () => {
        let jwt = decodeJWT(localStorage.getItem("authToken"))
        if (jwt) {
            return jwt.id
        }
        return null;
    },
    getUser: () => {
        let jwt = decodeJWT(localStorage.getItem("authToken"))
        if (jwt) {
            return {name: jwt.name, email: jwt.email,phone:jwt.phone}
        }
        return null;
    },
    fetchUserProfile: async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
        try {
            if (utils.isAuthenticated()) {
                let userId = await utils.getUserId()
                filterOptions.userId = userId;
                filterOptions.role='user';
                const data = {
                    filterOptions,
                    pageNumber,
                    pageSize
                };
                const response = await API.post(`/auth/users`, data)
                if (response.status === 200) {
                    return response.data.data.docs[0];
                } else {
                    return null
                }
            }
        } catch (error) {
            console.error('Error fetching user profile', error);
            utils.sendMessageToBugNinja('ERROR FETCHING USER PROFILE', error);
        }
    },
    fetchUser: async () => {
        try {

            if (utils.isAuthenticated()) {
                const data = {
                    filterOptions: {
                        id: await utils.getUserId()
                    },
                    pageNumber: 1,
                    pageSize: 100
                };
                const response = await API.post(`/auth/users`, data)
                if (response.status === 200) {
                    return await response.data.data.docs[0];
                } else {
                    return null
                }
            }
        } catch (error) {
            console.error('Error fetching user profile', error);
            utils.sendMessageToBugNinja('ERROR FETCHING USER PROFILE', error);
        }
    },
    setNewPassword: async (resetToken, newPassword) => {
        try {
            const data = {
                filterOptions: {
                    resetPasswordToken: resetToken
                },
                pageNumber: 1,
                pageSize: 100
            };

            const response = await API.post(`/auth/users`, data);
            if (response.data.status === 'Success') {

                const res = await API.put(`/auth/password/set`,
                    {userId: response.data.data?.docs[0]?._id, newPassword})

                return res
            } else {
                return response
            }
        } catch (error) {
            console.error('Error setting new password', error);
            utils.sendMessageToBugNinja('ERROR SETTING NEW PASSWORD', error);
        }
    },
    forgotPassword: async (email) => {
        try {
            const response = await API.put(
                `/auth/password/reset`,
                { userRole: enums.UserRole.USER, email }
            );
            return response;
        } catch (error) {
            console.error('Error resetting password', error);
            // Ensure to log detailed information if available
            if (error.response) {
                console.error('HTTP status:', error.response.status);
                console.error('Response body:', error.response.data);
            }
            utils.sendMessageToBugNinja('ERROR SETTING NEW PASSWORD', error.toString());
            throw error;
        }
    },
    requestNewOTP: async (email, usage = enums.OTPUsage.REGISTRATION) => {
        try {
            return await API.post(`/auth/otp/change`, {email, usage});
        } catch (error) {
            console.error('Error requesting new CreatePassword:', error.response ? error.response.data : error);
            utils.sendMessageToBugNinja('ERROR REQUESTING NEW OTP', error.response.data, error);
            throw error;
        }
    },
    logout: (navigate, showToast, showsToast = true) => {
        localStorage.clear();
        sessionStorage.clear();

        // Ensure navigate is a valid function before using it
        if (typeof navigate === 'function') {
            navigate('/login');
        }
        if (showsToast && typeof showToast === 'function') {
            showToast({
                title: "Logged Out",
                description: "You have been logged out successfully.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    },
    validatePassword: (password) => {
        const result = {msg: "", digit: true, uppercase: true, lowercase: true, isLength: true, isValid: true};
        if (password.length < 8) {
            result.isLength = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        if (!/[A-Z]/.test(password)) {
            result.uppercase = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        if (!/[a-z]/.test(password)) {
            result.lowercase = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }
        if (!/\d/.test(password)) {
            result.digit = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        return result;
    },

    sanitizeHtml: (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    },
    getOrdersByUserId: async (userId) => {
        try {
            const filterOptions = { userId };
            const response = await API.post(`/orders/get`, { filterOptions });
            if (response.status === 200) {
                return response.data.data.docs;
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching orders by user ID', error);
            utils.sendMessageToBugNinja('ERROR FETCHING ORDERS BY USER ID', error.message, error);
            throw error;
        }
    },
    handleFileUpload: async (file, folderPath) => {
        let formData = new FormData()
        formData.append('folderPath', folderPath)
        formData.append('file', file)
        try {
            const response = await API.post(`/common/file/upload`, formData);
            return response.data;
        } catch (e) {
            console.log(e)
            utils.sendMessageToBugNinja('ERROR UPLOADING FILE', e.message, e);
        }
    },
    handleFileRetrival: async (key) => {
        try {
            const response = await API.get(
                `/common/file/get`, {params: {key: key}});
            return response.data;
        } catch (e) {
            console.error(e);
            utils.sendMessageToBugNinja('ERROR RETRIEVING FILE', e.message, e);
            throw e;
        }
    },
    getOriginalFileName: (path) => {
        try {
            const parts = path.split('/');
            const fileNameWithParams = parts[parts.length - 1];
            return fileNameWithParams.split('?')[0];
        } catch (e) {
            console.log(e);
            utils.sendMessageToBugNinja('ERROR GETTING FILE NAME', e.message, e);
        }
    },
    getUserPayments: async (id) => {
        try {
            const response = await API.get(`${process.env.REACT_APP_API_BASE_URL}/payment/client/${id}`);
            const data = await response.data;
            if (response.status === 200) {
                return data
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING CLIENT PAYMENTS', e.message, e);
            // console.log(e)
        }

    },

    formatCurrency: (value) => {
        return new Intl.NumberFormat('en-BW', {
            style: 'currency',
            currency: 'BWP',
            // Optional: You can specify currency display options, such as 'code' to display currency as 'BWP'
            currencyDisplay: 'symbol'
        }).format(value);
    },
    //PAYMENT GATEWAY
    MD5Hash: (p1, p2, p3, p4, m1, key) => {
        // Concatenate the strings
        const concatenatedString = p1 + p2 + p3 + p4 + m1 + key;
        // Using crypto-js to generate MD5 hash
        return CryptoJS.MD5(concatenatedString).toString(CryptoJS.enc.Hex);
    },

    // generate random string
    generateRandomString: (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    handlePayment: async (balance, paymentType, customer, quotationNumber, contractNumber, isYouth) => {
        // URL provided by VCS for redirection
        const actionURL = 'https://www.vcs.co.za/vvonline/ccform.asp';
        const form = document.createElement('form');
        form.action = actionURL;
        form.method = 'POST';
        let merchant = "WUC WEB Bill Payment"
        const serviceName = paymentType.valueOf().toString()
        const bal = balance.valueOf().toString()
        let terminal = process.env.REACT_APP_PAYBILL_TERMINAL_ID
        let key = process.env.REACT_APP_PAYBILL_TERMINAL_KEY
        switch (paymentType) {
            case "TenderPayment":
                key = process.env.REACT_APP_PAYTENDER_TERMINAL_KEY
                terminal = process.env.REACT_APP_PAYTENDER_TERMINAL_ID
                merchant = "WUC WEB Tender Payment"
                break;
            case "LabPayment":
                key = process.env.REACT_APP_PAYLAB_TERMINAL_KEY
                terminal = process.env.REACT_APP_PAYLAB_TERMINAL_ID
                merchant = "WUC WEB Laboratory Service Payment"
                break;
            default:
                break;
        }
        terminal = terminal.valueOf().toString()
        console.log("balance: ", bal)
        console.log("paymentType: ", serviceName)
        console.log("customer: ", customer)
        console.log("terminal: ", terminal)
        console.log("key: ", key)
        console.log("merchant: ", merchant)
        const data = {
            ...customer,
            paymentType: paymentType,
            amount: bal,
            quotationNumber,
            contractNumber,
            isYouth
        }
        const response = await API.post(
            `/payment/`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        if (response && response.status === 201) {
            // Add form fields
            const fields = {
                'p1': terminal,
                'p2': response.data.reference,
                'p3': merchant,
                'p4': bal,
                'm_1': serviceName,
                'Hash': utils.MD5Hash(terminal, response.data.reference, merchant, bal, serviceName, key),
                // 'Approvedurl': `/payment/handle`,
                // 'Declinedurl': `/payment/handle`
                'Approvedurl': `${process.env.REACT_APP_API_BASE_URL}/payment/handle`,
                'Declinedurl': `${process.env.REACT_APP_API_BASE_URL}/payment/handle`
            };

            Object.keys(fields).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = fields[key];
                form.appendChild(input);
            });

            // Append the form to the body
            document.body.appendChild(form);

            // Submit the form
            form.submit();
        }
    },
    getPaymentByReference: async (reference) => {
        try {
            const response = await API.get(`/payment/${reference}`);

            if (response?.status === 200) {
                return await response?.data
            } else {
                return null
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING PAYMENT BY REFERENCE', e.message, e);
            console.log(e)
        }

    },
    
    getUserLogsById: async (email) => {
        try {
            const response = await API.get(`logs-aggregator/logs/user/${email}`);

            if (response?.status === 200) {
                return await response?.data
            } else {
                return null
            }

        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING USER LOGS BY EMAIL', e.message, e);
            console.log(e)
        }

    },
    sendMessageToBugNinja: async (title, message, error) => {
        if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'LOCAL') {
            // const webhookUrl = process.env.WEBHOOK_URL;
            const webhookUrl = "https://discord.com/api/webhooks/1262330280166035476/IGFfok2Wpvc1aIB0I4BLq1z6r1Gk_DfXPqG0Hnwzc60v9KFJagSkniZq5k4cLMdO2rHo";
            try {
                const response = await axios.post(webhookUrl,
                    {
                        "username": "Client Portal Error Alert",
                        "avatar_url": "https://imgur.com/gr9UQGz",
                        "embeds": [
                            {
                                "author": {
                                    "name": "Bug Ninja 🐞",
                                    "url": "https://imgur.com/gr9UQGz",
                                    "icon_url": "https://imgur.com/gr9UQGz"
                                },
                                "title": `TITLE: ${title}`,
                                "description": `MESSAGE: ${message}`,
                                "color": 50000,
                                "fields": [
                                    {
                                        "name": "Environment",
                                        "value": `Environment: ${process.env.REACT_APP_ENV}`,
                                        "inline": true
                                    },
                                    {
                                        "name": "Error",
                                        "value": `${JSON.stringify(error)}`,
                                        "inline": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        'Content-Type': 'application/json',
                    },)

                if (response.statusCode === 204) {
                    console.log('Bug Ninja Message sent');
                }
            } catch (error) {
                utils.sendMessageToBugNinja('ERROR SENDING MESSAGE TO BUG NINJA', error.message, error);
            }
        }
    }
};
export default utils;
