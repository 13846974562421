import React, { useState } from 'react';
import {
    Flex,
    Stack,
    Button,
    Box,
    Heading,
    useToast,
    FormLabel,
    FormControl,
    GridItem,
    Grid,
    Text,
    Select,
    HStack,
} from "@chakra-ui/react";
import { PropagateLoader } from "react-spinners";
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

function CostCalculatorComponent() {
    const [loading, setLoading] = useState(false);
    const [totalCost, setTotalCost] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [calculationComplete, setCalculationComplete] = useState(false);
    const toast = useToast();

    const handleSubmit = (values, { resetForm }) => {
        setLoading(true);
        setErrorMessage('');
        setCalculationComplete(false);

        setTimeout(() => {
            const { weight, length, width, height } = values;
            if (!weight || !length || !width || !height) {
                setErrorMessage('All dimensions must be selected.');
                setLoading(false);
                return;
            }

            const basePrice = getBasePrice(weight);
            const weightCoefficient = getWeightCoefficient(weight);
            const lengthCoefficient = getLengthCoefficient(length);
            const widthCoefficient = getWidthCoefficient(width);
            const heightCoefficient = getHeightCoefficient(height);

            const total = basePrice + (weightCoefficient * 50) + (lengthCoefficient * 15) + (widthCoefficient * 15) + (heightCoefficient * 15);
            setTotalCost(total);
            setLoading(false);
            setCalculationComplete(true);

            toast({
                title: "Calculation Complete",
                description: `Total transport cost is P${total.toFixed(2)}`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }, 1000);
    };

    const getBasePrice = (weightRange) => {
        const prices = {
            "< 5.0": 200,
            "5.1 - 10.0": 250,
            "10.1 - 20.0": 300,
            "20.1 - 30.0": 350,
            "30.1 - 40.0": 400,
            "40.1 - 50.0": 450,
            "50.1 - 60.0": 500,
            "60.1 - 70.0": 550,
            "70.1 - 80.0": 600,
            "80.1 - 90.0": 650,
            "90.1 - 100.0": 700,
            "> 100.0": 750
        };
        return prices[weightRange] || 0;
    };

    const getWeightCoefficient = (weightRange) => {
        const coefficients = {
            "< 5.0": 0,
            "5.1 - 10.0": 1.5,
            "10.1 - 20.0": 2,
            "20.1 - 30.0": 2.5,
            "30.1 - 40.0": 3,
            "40.1 - 50.0": 3.5,
            "50.1 - 60.0": 4,
            "60.1 - 70.0": 4.5,
            "70.1 - 80.0": 5,
            "80.1 - 90.0": 5.5,
            "90.1 - 100.0": 6,
            "> 100.0": 6.5
        };
        return coefficients[weightRange] || 0;
    };

    const getLengthCoefficient = (lengthRange) => {
        const coefficients = {
            "< 20.0": 1,
            "20.1 - 50.0": 5,
            "50.1 - 100.0": 10,
            "100.1 - 200.0": 15,
            "> 200.0": 20
        };
        return coefficients[lengthRange] || 0;
    };

    const getWidthCoefficient = (widthRange) => {
        const coefficients = {
            "< 20.0": 1,
            "20.1 - 50.0": 5,
            "50.1 - 100.0": 10,
            "100.1 - 200.0": 15,
            "> 200.0": 20
        };
        return coefficients[widthRange] || 0;
    };

    const getHeightCoefficient = (heightRange) => {
        const coefficients = {
            "< 20.0": 1,
            "20.1 - 50.0": 5,
            "50.1 - 100.0": 10,
            "100.1 - 200.0": 15,
            "> 200.0": 20
        };
        return coefficients[heightRange] || 0;
    };

    const handleReset = (resetForm) => {
        resetForm();
        setTotalCost(null);
        setCalculationComplete(false);
        setErrorMessage('');
    };

    return (
        <Grid templateColumns='repeat(12,1fr)'
              w={{ base: "full", md: "80%", lg: "60%" }}
              bg={"white"}
              gap={4}
              borderRadius={"md"}
              boxShadow={"md"}
              marginX="auto"  // Center the form
        >
            <GridItem colSpan={{ base: 12 }} p={{ base: 8 }}>
                <Box spacing={4} w={"full"}>
                    <Flex align={"center"} justify="start">
                        <Box mb={4}>
                            <Heading color="black" as="h6" size="lg">
                                Delivery Cost Calculator
                            </Heading>
                            <Text color={"black"} mt={-1} fontSize={"lg"}>
                                Calculate the transport cost for your package
                            </Text>
                        </Box>
                    </Flex>
                    <Formik
                        initialValues={{
                            weight: '',
                            length: '',
                            width: '',
                            height: ''
                        }}
                        validationSchema={Yup.object({
                            weight: Yup.string().required('Please select a weight'),
                            length: Yup.string().required('Please select a length'),
                            width: Yup.string().required('Please select a width'),
                            height: Yup.string().required('Please select a height')
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, resetForm }) => (
                            <Form>
                                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
                                    <GridItem colSpan={1}>
                                        <FormControl isInvalid={errors.weight && touched.weight}>
                                            <FormLabel color="black">Weight (kg)</FormLabel>
                                            <Field as={Select} name="weight" placeholder="Select weight">
                                                <option value="< 5.0">&lt; 5.0</option>
                                                <option value="5.1 - 10.0">5.1 - 10.0</option>
                                                <option value="10.1 - 20.0">10.1 - 20.0</option>
                                                <option value="20.1 - 30.0">20.1 - 30.0</option>
                                                <option value="30.1 - 40.0">30.1 - 40.0</option>
                                                <option value="40.1 - 50.0">40.1 - 50.0</option>
                                                <option value="50.1 - 60.0">50.1 - 60.0</option>
                                                <option value="60.1 - 70.0">60.1 - 70.0</option>
                                                <option value="70.1 - 80.0">70.1 - 80.0</option>
                                                <option value="80.1 - 90.0">80.1 - 90.0</option>
                                                <option value="90.1 - 100.0">90.1 - 100.0</option>
                                                <option value="> 100.0">&gt; 100.0</option>
                                            </Field>
                                            {errors.weight && touched.weight && (
                                                <Text color="red.500" fontSize="sm">{errors.weight}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <FormControl isInvalid={errors.length && touched.length}>
                                            <FormLabel color="black">Length (cm)</FormLabel>
                                            <Field as={Select} name="length" placeholder="Select length">
                                                <option value="< 20.0">&lt; 20.0</option>
                                                <option value="20.1 - 50.0">20.1 - 50.0</option>
                                                <option value="50.1 - 100.0">50.1 - 100.0</option>
                                                <option value="100.1 - 200.0">100.1 - 200.0</option>
                                                <option value="> 200.0">&gt; 200.0</option>
                                            </Field>
                                            {errors.length && touched.length && (
                                                <Text color="red.500" fontSize="sm">{errors.length}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <FormControl isInvalid={errors.width && touched.width}>
                                            <FormLabel color="black">Width (cm)</FormLabel>
                                            <Field as={Select} name="width" placeholder="Select width">
                                                <option value="< 20.0">&lt; 20.0</option>
                                                <option value="20.1 - 50.0">20.1 - 50.0</option>
                                                <option value="50.1 - 100.0">50.1 - 100.0</option>
                                                <option value="100.1 - 200.0">100.1 - 200.0</option>
                                                <option value="> 200.0">&gt; 200.0</option>
                                            </Field>
                                            {errors.width && touched.width && (
                                                <Text color="red.500" fontSize="sm">{errors.width}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <FormControl isInvalid={errors.height && touched.height}>
                                            <FormLabel color="black">Height (cm)</FormLabel>
                                            <Field as={Select} name="height" placeholder="Select height">
                                                <option value="< 20.0">&lt; 20.0</option>
                                                <option value="20.1 - 50.0">20.1 - 50.0</option>
                                                <option value="50.1 - 100.0">50.1 - 100.0</option>
                                                <option value="100.1 - 200.0">100.1 - 200.0</option>
                                                <option value="> 200.0">&gt; 200.0</option>
                                            </Field>
                                            {errors.height && touched.height && (
                                                <Text color="red.500" fontSize="sm">{errors.height}</Text>
                                            )}
                                        </FormControl>
                                    </GridItem>
                                </Grid>
                                <Stack spacing={6} mt={4}>
                                    <Button
                                        _hover={{
                                            bg: "black", color: "white", borderColor: "black",
                                        }}
                                        color="white"
                                        bg="brand.500"
                                        type="submit"
                                        variant="solid"
                                        isLoading={loading}
                                    >
                                        {loading ? <PropagateLoader size={8} color="white" /> : 'Calculate Delivery Cost'}
                                    </Button>
                                    {calculationComplete && (
                                        <HStack spacing={4} mt={4}>
                                            <Button
                                                variant="outline"
                                                borderColor="black"
                                                color="black"
                                                onClick={() => handleReset(resetForm)}
                                                borderWidth="2px"
                                                fontSize={{ base: "xs", md: "sm" }} // Small font size for mobile
                                                py={{ base: 2, md: 3 }} // Smaller padding for mobile
                                                px={{ base: 3, md: 4 }} // Smaller padding for mobile
                                            >
                                                Reset and Calculate Again
                                            </Button>
                                            <Button
                                                variant="outline"
                                                borderColor="black"
                                                color="black"
                                                borderWidth="2px"
                                                fontSize={{ base: "xs", md: "sm" }} // Small font size for mobile
                                                py={{ base: 2, md: 3 }} // Smaller padding for mobile
                                                px={{ base: 3, md: 4 }} // Smaller padding for mobile
                                                onClick={() => window.open('https://customer-portal.emarket.co.bw/', '_blank')}
                                            >
                                                Go to My Account
                                            </Button>
                                        </HStack>
                                    )}
                                    {errorMessage && (
                                        <Text color="red.500">{errorMessage}</Text>
                                    )}
                                    {totalCost !== null && (
                                        <Text fontWeight="bold" color="red.500">Total Transport Cost: P{totalCost.toFixed(2)}</Text>
                                    )}
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </GridItem>
        </Grid>
    );
}

export default CostCalculatorComponent;
