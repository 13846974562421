import React from 'react'
import { Flex } from '@chakra-ui/react'
import bg_image from "../../../assets/images/bg-cp.png";

const CostCalculatorContainer = ({Children}) => {
    return (
        <>
            {/*<Header/>*/}
            <Flex
                flexDirection="column"
                alignItems="center" // Align children vertically
                justifyContent="center"
                px={{ base:2, md: 10,lg:10}}
                mx="auto" // Center the Flex container itself horizontally
                py={{ base:32, md:48, lg:48 }}
                bgImage={`url('${bg_image}')`}
                backgroundSize="cover"
                backgroundPosition="center"
            >
                {Children}
            </Flex>
        </>
    )
}

export default CostCalculatorContainer
