import React from "react";
import CostCalculatorComponent from "./Components/CostCalculatorComponent";
import CostCalculatorContainer from "./Components/CostCalculatorContainer";

const CostCalculator = () => {
    return (
        <CostCalculatorContainer Children={ <CostCalculatorComponent />}/>
    );
};

export default CostCalculator;