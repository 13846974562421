import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
    Box,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    Divider,
    Image,
    CloseButton, useToast, IconButton, Spacer,
} from "@chakra-ui/react";
import {
    FiHome,
    FiMail,
    FiLogOut,FiBell, FiUser, FiSettings, FiHelpCircle,
} from "react-icons/fi";
import {MdOutlineLocalShipping} from "react-icons/md";
import Logo from "./../../assets/images/Logo.png";
import utils from "../../utils/commonFunctions.js";
import { useDrawer } from '../../providers/NavBarSliderProvider';
import { CustomThemedToast } from "../../utils/customChakraToasts";
import {RiBook2Line} from "react-icons/ri";

const LinkItems = [
    { name: "Dashboard", icon: FiHome, route: "/dashboard" },
    { name: "Order form", icon: MdOutlineLocalShipping, route: "/customer-portal/order-form" },
    { name: "Address book", icon: RiBook2Line, route: "/customer-portal/address-book" },
    { name: "Notifications", icon: FiBell, route: "/customer-portal/notifications" },
    { name: "Message", icon: FiMail, route: "/customer-portal/feedback-form" },
    { name: "My Profile", icon: FiUser, route: "/customer-portal/view-profile" },
    { name: "Help & Support", icon: FiHelpCircle, route: "/customer-portal/help-and-support" }
];

const Sidebar = () => {
    const location = useLocation();
    const { isOpen, closeDrawer } = useDrawer();
    const [activeLink, setActiveLink] = useState(""); // State to track the active link
    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    return (
        <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
            <SidebarContent
                onClose={() => closeDrawer}
                display={{ base: "none", md: "block" }}
                setActiveLink={setActiveLink}
                activeLink={activeLink}
            />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={closeDrawer}
                returnFocusCloseDrawer={false}
                onOverlayClick={closeDrawer}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent onClose={closeDrawer} />
                </DrawerContent>
            </Drawer>
            <Box ml={{ base: 0, md: 80 }} p="4">
                <Outlet />
            </Box>
        </Box>
    );
};

const SidebarContent = ({ onClose, setActiveLink, activeLink, ...rest }) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    const navigate = useNavigate();
    const showToast = useToast();
    const { addThemedToast } = CustomThemedToast();
    const { name } = utils?.getUser() || {};
    const handleLogout = () => {
        showToast.closeAll();
        utils.logout(navigate, showToast, true);
    };
    return (
        <Flex
            direction="column"
            bg={useColorModeValue("white", "gray.900")}
            zIndex={1}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: 80 }}
            h={'100vh'}
            pos="fixed"

            {...rest}
        >
            {/**header */}
            <Box
                bg={useColorModeValue("white", "gray.800")}
                px={{ base: "2", md: "4" }}
                py={{ base: "3", md: "5" }}
                my={2}
                mx={{ base: "1", md: "2" }}
                borderRadius={{ base: "5", md: "10" }}
            >
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                >
                    <Image src={Logo} maxH="120px" mb={{ base: "2", md: "0" }} />{" "}
                    {/* Margin bottom only on smaller screens */}
                    <Flex
                        direction="column"
                        align="center"
                        textAlign="center"
                    >
                        <Text fontSize="md" fontWeight="bold">
                            EMARKET BW
                        </Text>
                        <Text fontSize="sm" fontWeight="semibold">
                            Customer Portal
                        </Text>
                        {name && <Text fontSize="sm" isTruncated>{name}</Text>}

                        <CloseButton
                            position="absolute"
                            top={2}
                            right={2}
                            display={{ base: "flex", md: "none" }}
                            onClick={onClose}
                            color="brand.500"
                        />
                    </Flex>
                </Flex>
            </Box>


            <Divider mb={2} />
            {/**nav items */}
            <Box
                h={{ base: "420px", md: '420px' , lg: '420px'}}
                overflowY="auto"
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "4px", // Thinner scrollbar
                    },
                    "&::-webkit-scrollbar-track": {
                        background: trackColor,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "brand.500", // Using brand color
                        borderRadius: "8px",
                        border: `2px solid ${trackColor}`,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "brand.600", // Slightly darker brand color on hover
                    },
                }}
            >
                {LinkItems.map((link) => (
                    <NavItem
                        key={link.name}
                        icon={link.icon}
                        route={link.route}
                        isActive={activeLink === link.route}
                    >
                        {link.name}
                    </NavItem>
                ))}
            </Box>

            <Spacer />

            <Divider mt={2} mb={2}/>
            <Flex as="button" w={'full'} onClick={handleLogout} gap={3} justify={'center'} align={'center'} p={4}>
                <IconButton
                    p={2}
                    as={FiLogOut}
                    isRound
                    colorScheme="red"
                    variant="simple"
                    boxShadow={'md'}
                    cursor={'pointer'}
                    bg='white'
                    color="red.500"
                    _hover={{
                        bg: 'red.500',
                        color: 'white'
                    }}
                />
                <Text color={'red.500'}>Logout</Text>

            </Flex>
        </Flex>
    );
};

const NavItem = ({ icon, children, isActive, route, ...rest }) => {
    const activeBg = useColorModeValue("brand.500", "brand.500");
    const textColor = isActive ? "white" : "inherit";

    return (
        <NavLink
            to={route}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            {...rest}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={isActive ? activeBg : "transparent"}
                color={textColor}
                _hover={{
                    bg: "brand.500",
                    color: "white",
                }}
            >
                {icon && <Icon mr="4" fontSize="16" as={icon} />}
                {children}
            </Flex>
        </NavLink>
    );
};

export default Sidebar;
