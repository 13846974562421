import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import MessageFormDetails from "./Components/MessageFormDetails";


const MessageForm = () => {
    return <>
        <PortalContainer Title={"Message Form"} childComponent={ <MessageFormDetails/>}/>



    </>
};

export default MessageForm;
