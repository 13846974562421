import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/UpdateProfile/Profile";
import UpdateProfile from "./pages/Profile/UpdateCustomerProfile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import VerifyAccount from "./pages/VerifyAccount/VerifyAccount";
import PrivateRoute from "./components/Private/PrivateRoute";
import AuthRouter from "./components/Authentication/AuthRouter";
import VerificationRouter from "./components/Authentication/VerificationRouter";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Sidebar from "./components/SideBar/SideBar";
import AddressBook from "./pages/Address/Address";
import OrderForm from "./pages/OrderForm/OrderForm";
import MessageForm from "./pages/MessageForm/MessageForm";
import NotificationBook from "./pages/Notifications/Notifications";
import FAQs from "./pages/FAQ/FAQs";
import CostCalculator from "./pages/CostCalculator/CostCalculator";

const AppRoutes = () => {
    return (
        <Routes element={<AuthRouter/>}>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="cost-calculator" element={<CostCalculator />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password/*" element={<ResetPassword />} />
            <Route element={<VerificationRouter />}>
                <Route path="verify-account" element={<VerifyAccount />} />
            </Route>
            <Route element={<PrivateRoute />}>
                <Route element={<Sidebar />}>
                    <Route index element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="customer-portal/*">
                        <Route path="view-profile" index element={<Profile />} />
                        <Route path="update-profile" index element={<UpdateProfile />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="order-form" element={<OrderForm />} />
                        <Route path="feedback-form" element={<MessageForm />} />
                        <Route path="notifications" element={<NotificationBook />} />
                        <Route path="address-book" element={<AddressBook />} />
                        <Route path="help-and-support" element={<FAQs />} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default AppRoutes;
