import { extendTheme } from '@chakra-ui/react';

const sharedInputSelectStyles = {
  baseStyle: {
    field: {
      borderWidth: '2px',
      color: "gray.500",
      _focus: {
        borderColor: 'brand.500 !important',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500) !important',
      },
      _focusVisible: {
        borderColor: 'brand.500 !important',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500) !important',
      },
      _focusWithin: {
        borderColor: 'brand.500 !important',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500) !important',
      },
    },
  },
  sizes: {
    md: {
      field: {
        height: '45px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};



const theme = extendTheme({
  fonts: {
    body: 'DM Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: 'DM Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          borderRadius: '2xl',
        },
      }),
    },
    FormLabel: {
      baseStyle: {
        color: 'black',
      },
    },
    Input: {
      ...sharedInputSelectStyles,
    },
    Select: {
      ...sharedInputSelectStyles,
    },
    Textarea: {
      ...sharedInputSelectStyles,
    },
    Alert: {
      variants: {
        lightBlue: {
          container: {
            color: 'white',
            bg: 'brand.500',
          },
        },
      },
    },
    Button: {
      baseStyle: {},
      variants: {
        solid: {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600', // Darken the brand color on hover
          },
        },
        outline: {
          borderColor: 'brand.500',
          color: 'brand.500',
          _hover: {
            bg: 'brand.500', // Fill background on hover
            color: 'white',
          },
        },
        ghost: {
          color: 'brand.500',
          _hover: {
            bg: 'brand.100', // Use a lighter brand color for hover background
          },
        },
      },
      sizes: {
        md: {
          field: {
            height: '45px',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
  },
  colors: {
    brand: {
      100: '#F9F9F9', // Lighter brand color for ghost button hover state
      500: '#59E81C',
      600: '#17DF00', // Darker brand color for solid button hover state
      700: '#17DF00'
    },
  },
});

export default theme;
