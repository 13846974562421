import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import CustomerDetails from "./Components/CustomerDetails";


const UpdateProfile = () => {
    return <>
        <PortalContainer Title={"Update Profile"} childComponent={ <CustomerDetails/>}/>



    </>
};

export default UpdateProfile;
