import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import FAQDetails from "./Components/FAQDetails";


const FAQs = () => {
    return <>
        <PortalContainer Title={"FAQs"} childComponent={ <FAQDetails/>}/>



    </>
};

export default FAQs;
