import React from "react";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import ViewProfile from "./Components/ViewProfile";

const Profile = () => {
  return <>
    <PortalContainer Title={"View Profile"} childComponent={<ViewProfile/>}/>
  </>;
};

export default Profile;
