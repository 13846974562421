import React from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    VStack,
    Grid,
    GridItem,
    useToast,
    Box,
    FormErrorMessage,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { API } from '../../../utils/api';
import { useUserProfile } from "../../../providers/UserProfileProvider";
import utils from "../../../utils/commonFunctions";

const MessageFormDetails = () => {
    const { userProfile } = useUserProfile();
    const toast = useToast();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const userId = await utils.getUserId();
            const messageData = {
                ...values,
                userId, // Include the userId in the submission
                status: 'Pending'  // Default status for new messages
            };
            const response = await API.post('/message/add', messageData);

            // Reset the form with specific initial values
            resetForm({
                values: {
                    fullName: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
                    phoneNumber: userProfile ? userProfile.phone : '',
                    email: userProfile ? userProfile.email : '',
                    subject: '',
                    comments: '',
                },
            });

            toast({
                title: 'Success',
                description: 'Message submitted successfully!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            toast({
                title: 'Error',
                description: error?.message || 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box
            p={12}
            borderRadius="lg"
            bg={"white"}
        >
            <Formik
                initialValues={{
                    fullName: userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : '',
                    phoneNumber: userProfile ? userProfile.phone : '',
                    email: userProfile ? userProfile.email : '',
                    subject: '',
                    comments: '',
                }}
                validationSchema={Yup.object({
                    fullName: Yup.string().required('Full name is required.'),
                    phoneNumber: Yup.string()
                        .matches(/^[0-9]+$/, 'Phone number must contain only digits')
                        .min(7, 'Phone number must be at least 7 digits')
                        .max(15, 'Phone number must not exceed 15 digits')
                        .required('Phone number is required.'),
                    email: Yup.string().email('Invalid email address.').required('Email is required.'),
                    subject: Yup.string().required('Subject is required.'),
                    comments: Yup.string().required('Comments are required.'),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <VStack spacing={4} align="stretch">
                            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
                                <GridItem colSpan={1}>
                                    <Field name="fullName">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.fullName && form.touched.fullName}>
                                                <FormLabel>Full Name</FormLabel>
                                                <Input {...field} placeholder="Enter your full name" />
                                                <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="phoneNumber">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
                                                <FormLabel>Phone Number</FormLabel>
                                                <Input {...field} placeholder="Enter your phone number" />
                                                <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Field name="email">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                <FormLabel>Email</FormLabel>
                                                <Input {...field} placeholder="Enter your email" />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={{ base: 1, md: 2 }}>
                                    <Field name="subject">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.subject && form.touched.subject}>
                                                <FormLabel>Subject</FormLabel>
                                                <Input {...field} placeholder="Enter subject" />
                                                <FormErrorMessage>{form.errors.subject}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                                <GridItem colSpan={{ base: 1, md: 2 }}>
                                    <Field name="comments">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.comments && form.touched.comments}>
                                                <FormLabel>Comments</FormLabel>
                                                <Textarea
                                                    {...field}
                                                    placeholder="Enter your comments"
                                                    color="gray.500"
                                                    _focus={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                    _focusVisible={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                    _focusWithin={{
                                                        borderColor: 'brand.500',
                                                        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
                                                    }}
                                                />
                                                <FormErrorMessage>{form.errors.comments}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>
                            </Grid>
                            <Button type="submit" colorScheme="green" isLoading={isSubmitting}>
                                Submit Message
                            </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default MessageFormDetails;
