import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Spinner,
} from '@chakra-ui/react';
import AddressEditForm from './AddressEditForm';
import AddressViewForm from "./AddressViewForm";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { StyledTableContainer } from "../../../components/Styled/StyledComponents";
import PaginationControls from "../../../Context/PaginationControls/PaginationControls";
import { DeleteButton, EditButton, ViewButton } from "../../../components/Styled/ActionButtons";
import { deleteAddress, fetchAddressById, fetchAllAddresses } from "../AddressAPI";
import ConfirmDialog from "../../../Context/ConfirmDialog/ConfirmDialog";

const AddressTable = ({ addresses, setAddresses, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const handleEdit = (addressId) => {
        openDrawer({
            title: 'Edit Address',
            component: AddressEditForm,
            props: {
                addressId: addressId,
                refetchAddressData: refetchAddressData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${addressId}`,
        }, 'md');
    };

    const handleView = async (address) => {
        try {
            const addressData = await fetchAddressById(address._id);
            if (addressData) {
                openDrawer({
                    title: 'View Address',
                    component: AddressViewForm,
                    props: { formData: addressData },
                    key: `view-${address._id}`,
                }, 'lg');
            } else {
                throw new Error('Address data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const refetchAddressData = useCallback(async (options = {}) => {
        try {
            setLoading(true); // Set loading to true before fetching data
            const data = await fetchAllAddresses(pageNumber, 5, filterOptions);
            setAddresses(data.addresses);
            setTotalPages(Math.ceil(data.totalDocs / 5));
        } catch (error) {
            setError(error.message || "An error occurred.");
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    }, [filterOptions, pageNumber, setAddresses, setTotalPages]);

    useEffect(() => {
        refetchAddressData(filterOptions);
    }, [pageNumber, refetchAddressData, filterOptions, setRefreshFlag]);

    if (loading) {
        return (
            <Box textAlign="center" my="4" p="4">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100"><p>Error fetching Address data:</p><code>{error.toString()}</code></Box>;
    }

    if (dataLoaded && Array.isArray(addresses) && addresses.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const handleDelete = (addressId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this Address? This action cannot be undone.",
            onConfirm: () => confirmDelete(addressId),
        });
    };

    const confirmDelete = async (addressId) => {
        try {
            await deleteAddress(addressId);
            showToast({
                title: "Address Deleted",
                description: "Successfully deleted the Address.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
            setRefreshFlag(prev => !prev);
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: error.message || "Failed to delete the Address.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
        }
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Street Address</Th>
                            <Th>City/Town</Th>
                            <Th>Complex Name</Th>
                            <Th>Unit Number</Th>
                            <Th>Address Type</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {addresses.map((address, index) => (
                            <Tr key={index}>
                                <Td>{address.streetAddress ? address.streetAddress : 'N/A'}</Td>
                                <Td>{address.cityTown ? address.cityTown : 'N/A'}</Td>
                                <Td>{address.complexName ? address.complexName : 'N/A'}</Td>
                                <Td>{address.unitNumber ? address.unitNumber : 'N/A'}</Td>
                                <Td>{address.addressType ? address.addressType : 'N/A'}</Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(address)} />
                                    <EditButton onClick={() => handleEdit(address._id)} />
                                    <DeleteButton onClick={() => handleDelete(address._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog isOpen={confirmDialog.isOpen} title={confirmDialog.title} message={confirmDialog.message} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} onConfirm={confirmDialog.onConfirm} />
        </>
    );
};

export default AddressTable;
