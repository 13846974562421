import React from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, Button, FormErrorMessage,
    useToast, VStack, Icon, InputGroup, Text
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useUserProfile } from "../../../providers/UserProfileProvider";
import { API } from "../../../utils/api";
import { PropagateLoader } from "react-spinners";
import {NotAllowedIcon} from "@chakra-ui/icons";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";

// Custom toast hook for general notifications
export const useCustomGeneralToast = () => {
    const toast = useToast();

    const addGeneralToast = (newRes) => {
        toast({
            description: newRes.message,
            status: newRes.type,
            position: 'bottom',
            isClosable: true,
            duration: 5000,
        });
    };

    return { addGeneralToast };
};

// Validation schema
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string()
        .required('Mobile number is required')
        .matches(/^\S*$/, 'No whitespace allowed')
        .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid mobile number format'), // E.164 format
    alternativeMobileNumber: Yup.string()
        .matches(/^\S*$/, 'No whitespace allowed')
        .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid mobile number format')
        .nullable(),
    email: Yup.string().email('Invalid email address')
});

const CustomerDetails = () => {
    const { userProfile } = useUserProfile() || {};
    const { addGeneralToast } = CustomGeneralToast();
    const toast = useToast();

    const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        if (!userProfile || !userProfile._id) {
            setStatus({ success: false });
            addGeneralToast({
                message: "Invalid user ID. Cannot update profile.",
                type: "error"
            });
            setSubmitting(false);
            return;
        }

        try {
            const updatedValues = {
                ...values,
                _id: userProfile._id
            };
            const response = await API.put('/auth/user/update', updatedValues);

            if (response.status === 200) {
                setStatus({ success: true });
                toast.closeAll();
                addGeneralToast({ message: "Your profile has been updated successfully.", type: "success" });
            } else {
                setStatus({ success: false });
                toast.closeAll();
                addGeneralToast({ message: 'Failed to update profile', type: "error" });
            }
        } catch (error) {
            setStatus({ success: false });
            toast.closeAll();
            addGeneralToast({
                message: error.response?.data?.message || 'An error occurred while updating the profile.',
                type: "error"
            });
            console.error("Error updating profile:", error);
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <Box>
            <Formik
                initialValues={{
                    firstName: userProfile?.firstName || '',
                    lastName: userProfile?.lastName || '',
                    phone: userProfile?.phone || '',
                    alternativeMobileNumber: userProfile?.alternativeMobileNumber || '',
                    email: userProfile?.email || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <Flex direction={'column'} w={'full'} p={12} bg={'white'} borderRadius={'xl'}>
                            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
                                {/* First Name */}
                                <GridItem colSpan={{ base: 2, md: 1 }}>
                                    <Field name="firstName">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
                                                <FormLabel>First Name</FormLabel>
                                                <Input {...field} type="text" id="firstName" focusBorderColor="brand.500"/>
                                                <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>

                                {/* Last Name */}
                                <GridItem colSpan={{ base: 2, md: 1 }}>
                                    <Field name="lastName">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
                                                <FormLabel>Last Name</FormLabel>
                                                <Input {...field} type="text" id="lastName" focusBorderColor="brand.500"/>
                                                <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>

                                {/* Mobile Number */}
                                <GridItem colSpan={{ base: 2, md: 1 }}>
                                    <Field name="phone">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                                                <FormLabel>Mobile Number</FormLabel>
                                                <Input {...field} type="text" id="phone" focusBorderColor="brand.500"/>
                                                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>

                                {/* Alternative Mobile Number */}
                                <GridItem colSpan={{ base: 2, md: 1 }}>
                                    <Field name="alternativeMobileNumber">
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.alternativeMobileNumber && form.touched.alternativeMobileNumber}>
                                                <FormLabel>Alternative Mobile Number</FormLabel>
                                                <Input {...field} type="text" id="alternativeMobileNumber" focusBorderColor="brand.500"/>
                                                <FormErrorMessage>{form.errors.alternativeMobileNumber}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>

                                {/* Email (read-only) */}
                                <GridItem colSpan={{ base: 2, md: 1 }}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <FormControl>
                                                <FormLabel>Email Address</FormLabel>
                                                <InputGroup>
                                                    <Input
                                                        {...field}
                                                        type="email"
                                                        id="email"
                                                        readOnly
                                                        focusBorderColor="brand.500"
                                                        backgroundColor="gray.100"
                                                        _hover={{ backgroundColor: "gray.100" }}
                                                        _focus={{ backgroundColor: "gray.100" }}
                                                    />
                                                </InputGroup>
                                                <Text fontSize="sm" color="gray.600" mt={2}>
                                                    Note: Email Address cannot be updated
                                                </Text>
                                            </FormControl>
                                        )}
                                    </Field>
                                </GridItem>


                                {/* Submit Button */}
                                <GridItem colSpan={2}>
                                    <Button mt={4} colorScheme="brand" isLoading={isSubmitting} type="submit" w="full"
                                            disabled={isSubmitting}
                                            style={{
                                                opacity: isSubmitting ? 0.7 : 1,
                                                pointerEvents: isSubmitting ? 'none' : 'auto',
                                            }}
                                    >
                                        {isSubmitting ? (
                                            <> <PropagateLoader size={8} color="white"/>  Updating...
                                            </>
                                        ) : 'Update Profile'}
                                    </Button>
                                </GridItem>
                            </Grid>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CustomerDetails;
