import React from 'react';
import { CheckCircleIcon, AddIcon, EditIcon, DeleteIcon, CloseIcon, WarningIcon, SearchIcon } from '@chakra-ui/icons';
import { Button, Tooltip, useTheme, Stack } from '@chakra-ui/react';
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";

const buttonConfigs = {
    submit: {
        icon: <Icon icon="grommet-icons:save" />,
        tooltip: 'Save Record',
        colorKey: 'brand.500',
    },
    addNew: {
        icon: <AddIcon />,
        tooltip: 'Add New Record',
        colorKey: 'brand.500',
    },
    edit: {
        icon: <EditIcon />,
        tooltip: 'Edit',
        colorKey: 'yellow.400',
    },
    delete: {
        icon: <DeleteIcon />,
        tooltip: 'Delete',
        colorKey: 'red.500',
    },
    reset: {
        icon: <Icon icon="radix-icons:reset" />,
        tooltip: 'Reset Form',
        colorKey: 'orange.400',
    },
    cancel: {
        icon: <CloseIcon />,
        tooltip: 'Cancel',
        colorKey: 'gray.50',
        variant: 'outline',
        color: 'gray.800',
        borderColor: 'gray.200',
        _hover: {
            bgColor: 'gray.100',
            color: 'gray.800',
        }
    },
    suspend: {
        icon: <WarningIcon />,
        tooltip: 'Suspend',
        colorKey: 'purple.500',
    },
    view: {
        icon: <Icon icon="ep:view" />,
        tooltip: 'View',
        colorKey: 'teal.300',
    },
    saveAndClose: {
        icon: <CheckCircleIcon />,
        tooltip: 'Save and Close',
        colorKey: 'green.600',
    },
    refresh: {
        icon: <Icon icon="flowbite:refresh-outline" />,
        tooltip: 'Refresh',
        colorKey: 'gray.1000',
    },
    search: {
        icon: <SearchIcon />,
        tooltip: 'Search',
        colorKey: 'gray.200',
    },
    logout: {
        icon: <Icon icon="ant-design:logout-outlined" />,
        tooltip: 'Logout',
        colorKey: 'red.500',
    },
    home: {
        icon: <Icon icon="material-symbols:wifi-home-outline" />,
        tooltip: 'Home',
        colorKey: 'brand.500',
    },
    viewProfile: {
        icon: <Icon icon="material-symbols:admin-panel-settings-outline" />,
        tooltip: 'View Admin Profile',
        colorKey: 'brand.500',
    },
    resetPassword: {
        icon: <Icon icon="carbon:password" />,
        tooltip: 'Reset Password',
        colorKey: 'brand.500',
    },
};

const ButtonStack = styled(Stack)`
  @media (min-width: ${({ theme }) => theme.breakpoints.base}) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[6]};
`;

export const CustomButton = ({
                          tooltipLabel,
                          type,
                          children,
                          icon: customIcon,
                          showIcon = true,
                          showLabel = true,
                          showTooltip = true,
                          ...rest
                      }) => {
    const theme = useTheme();
    const config = buttonConfigs[type] || {}; // Provide a default empty object if config is undefined

    const icon = customIcon ? React.cloneElement(customIcon, { style: { marginRight: showLabel ? '2px' : 0 } }) :
        (showIcon ? React.cloneElement(config.icon, { style: { marginRight: showLabel ? '2px' : 0 } }) : undefined);

    const [baseColor, shade] = (config.colorKey || 'gray.500').split('.'); // Provide a default color if colorKey is undefined
    const bgColor = theme.colors[baseColor][shade];
    const hoverBgColor = theme.colors[baseColor][parseInt(shade) + 100];

    const buttonProps = {
        variant: config.variant || 'solid',
        leftIcon: icon,
        bg: bgColor,
        color: config.color || 'white',
        fontWeight: 'normal',
        borderColor: config.borderColor,
        _hover: {
            bg: hoverBgColor,
            color: config._hover?.color || 'white',
            borderColor: config._hover?.borderColor || config.borderColor,
        },
        _focus: { boxShadow: 'none' },
        ...(showLabel ? {} : {
            px: 4,
            iconSpacing: 0,
        }),
        ...rest
    };

    const tooltipText = tooltipLabel || config.tooltip || '';

    return (
        <Tooltip label={tooltipText} hasArrow fontSize="xs" isDisabled={!showTooltip}>
            <Button {...buttonProps} type={(type === 'submit' || type === 'search') ? 'submit' : 'button'}>
                {showLabel && children}
            </Button>
        </Tooltip>
    );
};


const CustomSearchIcon = ({ size = '1em', ...props }) => (
    <Icon icon="bi:search" width={size} height={size} {...props} />
);

export { ButtonStack, CustomSearchIcon };
