import React, { useEffect, useRef, useState } from 'react';
import {
    FormControl, FormLabel, GridItem, VStack, Box, Text, InputGroup,
    Input, SimpleGrid, Spinner, Switch, Badge, Select, FormErrorMessage
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomAlert from "../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import { fetchAddressById, updateAddress } from "../AddressAPI";
import FormActionLabel from "../../../components/Styled/FormActionLabel";

const AddressEditForm = ({ addressId, refetchAddressData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [initialValues, setInitialValues] = useState({
        addressType: '',
        streetAddress: '',
        cityTown: '',
        complexName: '',
        unitNumber: ''
    });
    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchAndSetAddressData = async () => {
            if (!hasFetched.current) {
                try {
                    const addressData = await fetchAddressById(addressId);
                    if (addressData) {
                        setInitialValues({
                            addressType: addressData.addressType,
                            streetAddress: addressData.streetAddress,
                            cityTown: addressData.cityTown,
                            complexName: addressData.complexName,
                            unitNumber: addressData.unitNumber
                        });
                    }
                    hasFetched.current = true;
                } catch (error) {
                    showToast({
                        title: 'Error fetching Address data',
                        description: error.message || 'An unexpected error occurred.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        };

        fetchAndSetAddressData();
    }, [addressId]);

    const addressSchema = Yup.object().shape({
        addressType: Yup.string().oneOf(['Residential', 'Business', 'Other'], 'Invalid address type').required('Address type is required.'),
        streetAddress: Yup.string().required('Street address is required.'),
        cityTown: Yup.string().required('City/Town is required.'),
        complexName: Yup.string(),
        unitNumber: Yup.string()
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateAddress(addressId, values);
            showToast({
                title: 'Address updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchAddressData();
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating Address',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Address" />
            <Formik
                initialValues={initialValues}
                validationSchema={addressSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formikProps => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <Field name="addressType">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.addressType && form.touched.addressType}>
                                            <FormLabel htmlFor="addressType">Address Type</FormLabel>
                                            <Select {...field} id="addressType" placeholder="Select Address Type">
                                                {['Residential', 'Business', 'Other'].map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{form.errors.addressType}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="streetAddress">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.streetAddress && form.touched.streetAddress}>
                                            <FormLabel htmlFor="streetAddress">Street Address</FormLabel>
                                            <Input {...field} id="streetAddress" placeholder="Enter Street Address" />
                                            <FormErrorMessage>{form.errors.streetAddress}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="cityTown">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.cityTown && form.touched.cityTown}>
                                            <FormLabel htmlFor="cityTown">City/Town</FormLabel>
                                            <Input {...field} id="cityTown" placeholder="Enter City/Town" />
                                            <FormErrorMessage>{form.errors.cityTown}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="complexName">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.complexName && form.touched.complexName}>
                                            <FormLabel htmlFor="complexName">Complex Name</FormLabel>
                                            <Input {...field} id="complexName" placeholder="Enter Complex Name" />
                                            <FormErrorMessage>{form.errors.complexName}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="unitNumber">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.unitNumber && form.touched.unitNumber}>
                                            <FormLabel htmlFor="unitNumber">Unit Number</FormLabel>
                                            <Input {...field} id="unitNumber" placeholder="Enter Unit Number" />
                                            <FormErrorMessage>{form.errors.unitNumber}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </SimpleGrid>
                            <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                            <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                                    Cancel
                                </CustomButton>
                                <CustomButton type="submit" disabled={formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? (
                                        <Spinner size="xs" mr={2} />) : 'Update'}
                                </CustomButton>
                            </ButtonStack>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddressEditForm;
