import React from "react";

import {
    Box,
    HStack,
    Text,
    Tooltip,
    WrapItem,
    Avatar, Menu, MenuButton, MenuList, MenuItem, Flex, VStack, Icon, useToast,
} from "@chakra-ui/react";

import { MdEdit } from "react-icons/md";
import { IoMdKey } from "react-icons/io";
import {CustomMenuButton} from "./Portal/CustomMenu";
import {Link, useNavigate} from "react-router-dom";
import utils from "../../utils/commonFunctions";
import {LuLogOut} from "react-icons/lu";
import {CustomThemedToast} from "../../utils/customChakraToasts";

const ProfileMenu = () => {
  const user=utils.getUser()
    const navigate=useNavigate()
    const toast =useToast()
    const {showToast}=CustomThemedToast()
    const handleLogout = () => {
        toast.closeAll();
        utils.logout(navigate, showToast, true);
    };

  return (
    <Box>
          <Box
            bg={"white"}
            w="full"
          >
            <Box bg={"gray.50"} borderTopRadius={'2xl'} p="4">
              <Text my={2} textAlign={'center'}>EMarket BW</Text>
              <Flex justify={'center'} align={'center'}>
                  <Avatar

                      name={user?.name}
                      size={"xl"}
                      color={"white"}
                      style={{
                        border: "6px white solid",
                      }}
                      borderRadius={"full"}
                  />

              </Flex>

            </Box>

            <Box align={"center"} justifyContent={"center"} py="4" px="8">
              <Box mb={4}>
                <Text fontSize={"md"} fontWeight={"medium"}>
                  {user?.name}
                </Text>
                <Text fontSize={"sm"}>{user?.email}</Text>
              </Box>

              <HStack align={"center"} justifyContent={"center"}>
                  <CustomMenuButton  icon={MdEdit} color={'brand.500'} bg={'white'} tip={'Profile'} url={'/customer-portal/view-profile'}/>
                  <CustomMenuButton onClick={handleLogout} icon={LuLogOut} color={'red.500'} bg={'white'} tip={'Logout'}/>
              </HStack>
            </Box>
          </Box>
    </Box>
  );
};
function ProfileButton() {

  const {name,email}=utils?.getUser()||{}
  return (

        <>
          <Menu zIndex={900000} >
            <Tooltip label="View Profile" fontSize={['xs']} bg={'gray.600'} fontWeight={'light'} border={'2xl'}>
              <MenuButton>
                <WrapItem>
                  <Avatar
                      size="md"
                      name={name&&`${name}`}
                      bg={'brand.500'}
                      color={'white'}
                      boxShadow={'md'}
                      src=""
                  />{" "}
                </WrapItem>
              </MenuButton>
            </Tooltip>
            <MenuList borderRadius={'2xl'} borderColor={'white'} mt={[4]}>
              <MenuItem bg={'transparent'}>
                <ProfileMenu isOpen={true} user={{name,email}}/>
              </MenuItem>
            </MenuList>
          </Menu>

        </>


  );
}

export default ProfileButton;
