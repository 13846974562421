import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import AddressContainer from "./Components/AddressContainer";


const AddressBook = () => {
    return <>
        <PortalContainer Title={"Address Book"} childComponent={ <AddressContainer/>}/>



    </>
};

export default AddressBook;
