import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Icon,
  Button,
  Text,
  Heading,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as ChakraLink } from "@chakra-ui/react";
import { FaEye } from 'react-icons/fa';
import {MdOutlineInfo, MdSearchOff} from 'react-icons/md';
import DashboardHeader from '../../components/Header/Portal/DashboardHeader';
import utils from '../../utils/commonFunctions';
import PdfViewerModal from "../../components/PdfViewer/PdfViewerModal";
import dashboardBanner from "../../assets/Banners/dashboard-banner.png"

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(true); // Loading state
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const userId = utils.getUserId();
    setUserId(userId);
    console.log("UserId: " + userId);

    async function fetchOrders() {
      try {
        const orders = await utils.getOrdersByUserId(userId);
        setOrders(orders);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    }

    if (userId) {
      fetchOrders();
    }
  }, [userId]);

  const handleViewInvoice = (invoicePath) => {
    setSelectedInvoice(invoicePath);
    onOpen();
  };

  return (
      <>
        <DashboardHeader />
        <Flex direction="column" alignItems="center" justifyContent="center" w="100%">
          {/* Banner Image */}
          <ChakraLink href="/customer-portal/view-profile" style={{ textDecoration: 'none' }}>
            <Box w="100%" maxW="1200px" p={4} mb={4}>
              <Image
                  src={dashboardBanner}
                  alt="Dashboard Banner"
                  w="100%"
                  h="auto"
                  borderRadius="lg"
                  objectFit="cover"
              />
            </Box>
          </ChakraLink>

          {/* Recent Orders Heading */}
          <Box w="100%" maxW="1200px" p={4} mb={4}>
            <Heading as="h2" size="lg" mb={4}>
              Recent Orders
            </Heading>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" h="200px">
                  <Spinner size="lg" />
                </Box>
            ) : (
                orders.length === 0 ? (
                    <Box textAlign="center" py={10} px={6}>
                      <Icon as={MdSearchOff} boxSize={20} color="gray.400" />
                      <Heading fontSize="2xl" mt={6} mb={2} color="gray.600">
                        No Records Found
                      </Heading>
                      <Text color="gray.500" fontSize="lg">
                        It seems there are no orders to display right now.
                      </Text>
                    </Box>
                ) : (
                    <Box overflowX="auto">
                      <Table variant="outline" size="md" borderWidth="1px" borderRadius="lg">
                        <Thead bg="#59E81C">
                          <Tr>
                            <Th color="black">Order ID</Th>
                            <Th color="black">Date</Th>
                            <Th color="black">Additional Requests</Th>
                            <Th color="black">Invoice</Th>
                            <Th color="black">Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {orders.map((order) => (
                              <Tr key={order._id} _hover={{ bg: "gray.50" }}>
                                <Td>
                                  <Tooltip label="Order ID" fontSize="md">
                                    <Box>{order._id}</Box>
                                  </Tooltip>
                                </Td>
                                <Td>{new Date(order.date).toLocaleDateString()}</Td>
                                <Td>{order.additionalRequests || 'None'}</Td>
                                <Td>
                                  <Tooltip label="View Invoice" fontSize="md">
                                    <Button
                                        size="xs"
                                        colorScheme="teal"
                                        rightIcon={<FaEye />}
                                        onClick={() => handleViewInvoice(order.invoice)}
                                    >
                                      View
                                    </Button>
                                  </Tooltip>
                                </Td>
                                <Td>
                                  <Tooltip label={`Status: ${order.status}`} fontSize="md">
                                    <Box display="flex" alignItems="center">
                                      <Icon as={MdOutlineInfo} w={5} h={5} color={order.status === 'Pending' ? 'orange.400' : 'green.400'} mr={2} />
                                      {order.status}
                                    </Box>
                                  </Tooltip>
                                </Td>
                              </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                )
            )}
          </Box>

          {/* PDF Viewer Modal */}
          <PdfViewerModal filePath={selectedInvoice} isOpen={isOpen} onClose={onClose} />
        </Flex>
      </>
  );
};

export default Dashboard;
