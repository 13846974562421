import React from 'react';
import { Flex, chakra, Text, Link, HStack } from '@chakra-ui/react';
import { PiCopyrightFill } from 'react-icons/pi';

const Footer = () => {
  return (
    <Flex
      w='full'
      bg='gray.50'
      _dark={{
        bg: '#3e3e3e',
      }}
      alignItems='center'
      justifyContent='center'
      borderRadius={'2xl'}
    >
      <Flex
        w='full'
        as='footer'
        flexDir={{
          base: 'column',
          sm: 'row',
        }}
        align='center'
        justify='space-between'
        px='6'
        py='4'
        borderRadius={'2xl'}
        bg='white'
        _dark={{
          bg: 'gray.800',
        }}
      >
        <chakra.a
          fontSize='xl'
          fontWeight='bold'
          color='gray.600'
          _hover={{
            color: 'gray.700',
          }}
          href='https://emarket.co.bw/'
          isExternal
          target='_blank'
          rel='noopener noreferrer'
        >
          <HStack>
            <PiCopyrightFill
              style={{ marginRight: '8px', verticalAlign: 'middle' }}
            />
            <Text ml={-2}>Emarket BW.</Text>
          </HStack>
        </chakra.a>

        <chakra.p
          py={{
            base: '2',
            sm: '0',
          }}
          pl={{
            base: '0',
            sm: '40',
          }}
          color='gray.800'
          _dark={{
            color: 'white',
          }}
        >
          All rights reserved
        </chakra.p>

        <Flex mx='-2' pr={{md:4}}>
          <Link
            href='https://weblogic.co.bw'
            isExternal
            _hover={{ textDecoration: 'none' }}
          >
            <Text>Designed & Developed By Weblogic</Text>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
