import React, {useEffect, useState} from "react";
import {
    Box,
    Container,
    Stack,
    SimpleGrid,
    Text, Button, Spacer,
} from "@chakra-ui/react";
import bg_image from './../../assets/footerBackground.png';
import utils from "../../utils/commonFunctions";
import {Link, useNavigate} from "react-router-dom";

const ListHeader = ({children}) => (
    <Text fontWeight="bold" fontSize="16px" color="#000000" mb={4}>
        {children}
    </Text>
);

const FooterLink = ({children, href, onClick, includeHyphen = true}) => {

    return (
        <>
            {onClick &&
                <Box
                    onClick={onClick}
                    _hover={{textDecoration: "none"}}
                    fontSize="16px"
                    bg={"transparent"}
                    cursor={'pointer'}
                >
                    {includeHyphen ? "- " : ""}
                    {children}
                </Box>
            }
            {!onClick &&
                <Link
                    to={href}
                    onClick={onClick}
                    cursor={'pointer'}
                    color="#747379"
                    _hover={{textDecoration: "none"}}
                    fontSize="13px"
                >
                    {includeHyphen ? "- " : ""}
                    {children}
                </Link>
            }

        </>
    )


};

const Footer = () => {
    const [categories, setCategories] = useState("")
    const navigate = useNavigate()
    const formatUrl = (input) => {
        return input
            .toLowerCase()
            .replace(/\s+/g, '-')
    };
    const getCategories = async () => {
        try {
            let categories = await utils.fetchPublicationsCategories()
            setCategories(categories)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getCategories()
    }, []);
    const handleClick = (categoryId,categoryName, href) => {
        navigate(formatUrl(href), {state: {category: categoryId,name:categoryName}});
    };

    return (
        <SimpleGrid bg={'whiteAlpha.400'}>
            {/*<Box bgImage={`url('${bg_image}')`} style={{filter: 'grayscale(-100%)'}} bgPos={'bottom'} bgSize="cover"
                 height={{base: "1300px", md: '480px'}}>
                <Container as={Stack} maxW="7xl" py={10}>
                    <SimpleGrid columns={{base: 1, sm: 2, md: 5}} spacing={0}>
                        <Stack align="flex-start">
                            <ListHeader>WUC AT GLANCE</ListHeader>
                            <FooterLink href="/about">WUC at a Glance</FooterLink>
                            <FooterLink href="/about">Corporate Profile</FooterLink>
                            <FooterLink href="/about">Corporate Governance</FooterLink>
                            <FooterLink href="/about">Board of Directors</FooterLink>
                            <FooterLink href="/about">Corporate Management Team</FooterLink>
                            <FooterLink href="/about">Corporate Structure</FooterLink>
                            <FooterLink href="/about">Corporate Social Initiatives</FooterLink>
                        </Stack>

                        <Stack align="flex-start">
                            <ListHeader>PUBLICATIONS</ListHeader>
                            {categories && categories?.length > 0 && categories.map((category) => (
                                <>
                                    <FooterLink
                                        cursor={'pointer'}
                                        onClick={() => handleClick(category._id,category.name, `/publications/category/${category.name.toLowerCase()}`)}
                                    >
                                        {category.name}
                                    </FooterLink>
                                </>

                            ))}

                        </Stack>

                        <Stack align="flex-start" pl={{base: 0, sm: 0}}>
                            <ListHeader>GALLERIES</ListHeader>
                            <FooterLink href="/media/pictures">Pictures</FooterLink>
                            <FooterLink href="/media/videos">Videos</FooterLink>
                            <FooterLink href="/projects">Project</FooterLink>
                            <FooterLink href="/media/faq">FAQS</FooterLink>
                            <FooterLink href="/media/events">Events</FooterLink>
                        </Stack>

                        <Stack spacing={6}>
                            <Stack align="flex-start">
                                <ListHeader>CAREERS & TENDERS</ListHeader>
                                <FooterLink href="https://careers.wuc.bw">Careers</FooterLink>
                                <FooterLink href="/tenders">Tenders</FooterLink>
                                <FooterLink href="https://vendorportal.wuc.bw/landing">Vendor</FooterLink>
                            </Stack>

                            <Stack align="flex-start">
                                <ListHeader>FOR STAFF</ListHeader>
                                <FooterLink
                                    href="https://owa.wuc.bw/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fowa.wuc.bw%2fowa"> Staff
                                    Email</FooterLink>
                                <FooterLink href="https://login.microsoftonline.com/"> Staff Portal</FooterLink>
                            </Stack>
                        </Stack>

                        <Stack align="flex-start">
                            <ListHeader>CONTACT US</ListHeader>
                            <FooterLink href="tel:0800555555" >
                                Toll Free : 0800 555 555
                            </FooterLink>
                            <FooterLink href="tel:3992500" >
                                Land Line : 399 25 00
                            </FooterLink>
                            <FooterLink href='mailto:contactcentre@wuc.bw' >
                                E-mail:contactcentre@wuc.bw
                            </FooterLink>
                            <FooterLink href="#" >
                                SMS: 16299
                            </FooterLink>
                            <FooterLink href="#" >
                                USSD: *186#
                            </FooterLink>
                            <FooterLink href="https://wa.me/26773042819" >
                                WhatsApp : 73042819{" "}
                            </FooterLink>
                            <FooterLink href="https://www.facebook.com/waterutilities" >
                                Facebook Messenger : Water Utilities Corporation
                            </FooterLink>
                            <FooterLink href="https://x.com/_wuc?lang=en" >
                                Twitter : _WUC
                            </FooterLink>
                            <FooterLink href="#" >
                                Improved Queries & Complaints Procedure
                            </FooterLink>
                            <FooterLink href="/wuc-branch-locator" >
                                Branch Locator
                            </FooterLink>
                        </Stack>
                    </SimpleGrid>
                </Container>
            </Box>*/}

            <Box w={'full'} bg="#000000">
                <Container
                    as={Stack}
                    maxW="7xl"
                    py={4}
                    direction={{base: "column", md: "row"}}
                    spacing={4}
                    justify="center"
                    align="center"
                >
                    <Text color="white" fontSize="14px"  textAlign={'center'}>
                        eMarketBW © Copyright 2024. All rights reserved. {" "}
                        <Link
                            href="/"
                            color="white"
                            ml={1}
                            _hover={{textDecoration: "none", cursor: "pointer"}}
                        >
                            <FooterLink href="#" >
                                Terms & Conditions
                            </FooterLink>
                        </Link>
                    </Text>
                    <Spacer/>
                    <Text color="white" fontSize="14px">
                            <FooterLink href="http://www.weblogic.co.bw" >
                                Designed and developed by Weblogic
                            </FooterLink>
                    </Text>
                </Container>
            </Box>
        </SimpleGrid>
    );
};

export default Footer;
