import {
    Flex,
    Input,
    Stack,
    Icon,
    Text,
    Button,
    Box,
    Heading,
    useToast,
    FormLabel,
    FormControl,
    VStack,
    HStack,
    Link,
    InputGroup,

} from "@chakra-ui/react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {Link as RouterLink} from "react-router-dom";
import {API} from "../../../utils/api";

import {LiaUnlockAltSolid} from "react-icons/lia";
import enums from "wuc-library/enums";
import utils from "../../../utils/commonFunctions";
import {RiLockUnlockLine} from "react-icons/ri";
function ResetPassword() {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();



    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!email || !email.trim()) {
            toast.closeAll();
            addGeneralToast({
                message: "Please enter a valid email address.",
                type: "warning",
            });
            setLoading(false);
            return;
        }

        try {
            const response = await utils.forgotPassword(email);
            setShow(true);
            addGeneralToast({
                message: "Please check your email to reset your password.",
                type: "success",
            });
        } catch (error) {
            console.error("Error during password reset:", error);
            toast.closeAll();
            let errorMessage = "An unexpected error occurred during password reset. Please try again.";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            addGeneralToast({
                message: errorMessage,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };






    return (
        <>

            <Stack
                maxW={{base: "full", md: 'md'}}
                minW={{base: "full", md: 'md'}}
                minH={'sm'}
                bg={"white"}
                p={8}
                borderRadius={"xl"}
                boxShadow={"md"}
                direction={{base: "column", md: "row"}}
                justify={'center'}
                align={'center'}
            >
                <Stack spacing={4} w={"full"}>


                    {show ?<>
                            <Flex align={"center"} justify="center">
                                <VStack>
                                    <Box
                                        backgroundColor="brand.500"
                                        borderRadius="50%"
                                        width="50px"
                                        height="50px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%"/>
                                    </Box>
                                    <Heading color="black" as="h6" size="lg">
                                        Reset Link Sent
                                    </Heading>
                                    <Text color={"black"} mt={-1} fontSize={"lg"}>
                                       Password reset link has been sent. Please check your email
                                    </Text>
                                </VStack>
                            </Flex>
                        </>



                        : <form onSubmit={handleForgotPassword}>
                            <Flex align={"center"} justify="center">
                                <VStack>
                                    <Box
                                        backgroundColor="brand.500"
                                        borderRadius="50%"
                                        width="50px"
                                        height="50px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Icon as={RiLockUnlockLine} color="white" boxSize="50%" />
                                    </Box>
                                    <Heading color="black" as="h6" size="lg">
                                        Reset Password
                                    </Heading>
                                    <Text color={"black"} mt={-1} fontSize={"lg"}>
                                        Reset password for your account
                                    </Text>
                                </VStack>
                            </Flex>
                            <FormControl mb={2} id="email" mt={2}>
                                <FormLabel color="black">Email</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={"email"}
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        focusBorderColor="brand.500"

                                    />
                                </InputGroup>
                            </FormControl>
                            <Stack spacing={6}>
                                <Button
                                    _hover={{
                                        bg: "brand.500",
                                        color: "white",
                                    }}
                                    color="white"
                                    bg="brand.500"
                                    type="submit"
                                    variant="solid"
                                >
                                    {loading ? (
                                        <>
                                            <PropagateLoader size={8} color="white"/>
                                        </>
                                    ) : (
                                        <Text>Reset Password</Text>
                                    )}
                                </Button>

                                <HStack justifyContent="center" alignItems="center">
                                    <Text color={"black"}>Don't have an account?</Text>
                                    <RouterLink to="/register">
                                        <Link color="brand.500" _hover={{textDecoration: "none"}}>
                                            Register
                                        </Link>
                                    </RouterLink>
                                </HStack>
                            </Stack>
                        </form>}

                </Stack>
            </Stack>
        </>
    );
}

export default ResetPassword;
