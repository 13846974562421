import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import NotificationContainer from "./Components/NotificationContainer";


const NotificationBook = () => {
    return <>
        <PortalContainer Title={"Notification Book"} childComponent={ <NotificationContainer/>}/>



    </>
};

export default NotificationBook;
