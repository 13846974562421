import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Flex } from '@chakra-ui/react'
import bg_image from "../../assets/images/bg-cp.png";

const AuthenticationContainer = ({Children}) => {
  return (
    <>
    {/*<Header/>*/}
    <Flex
        flexDirection="column"
        alignItems="center" // Align children vertically
        justifyContent="center"
        px={{ base:2, md: 10,lg:10}}
        py={{base:32}}
        bgImage={`url('${bg_image}')`}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        {Children}
      </Flex>
      <Footer/>
    </>
  )
}

export default AuthenticationContainer
