import {
    Flex,
    Input,
    Stack,
    Icon,
    Button,
    Box,
    Heading,
    useToast,
    FormLabel,
    FormControl,
    VStack,
    HStack,
    Link,
    InputGroup,
    InputRightElement,
    IconButton,
    GridItem,
    Grid,
    Text,
    List,
    ListItem,
    ListIcon, Show, Hide,
} from "@chakra-ui/react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {Link as RouterLink} from "react-router-dom";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import utils from "../../../utils/commonFunctions";

import React from "react";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import {API} from "../../../utils/api";
import {LiaUnlockAltSolid} from "react-icons/lia";
import {HiUserAdd} from "react-icons/hi";
import {IoPersonAddOutline} from "react-icons/io5";
import {RiLockPasswordLine} from "react-icons/ri";

function Credentials({handleSetPassword, setConPassword, setUserEmail, setPhone, show, handleClick}) {
    return (<>
        <GridItem colSpan={{base:12,md:6}}>
            <FormControl isRequired id="userEmail">
                <FormLabel color="black">Email</FormLabel>
                <InputGroup>
                    <Input
                        type={"email"}
                        name="email"
                        focusBorderColor="brand.500"
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                </InputGroup>
            </FormControl>
        </GridItem>
        <GridItem colSpan={{base:12,md:6}}>
            <FormControl isRequired id="email">
                <FormLabel color="black">Phone</FormLabel>
                <InputGroup>
                    <Input
                        type={"number"}
                        name="phone"
                        focusBorderColor="brand.500"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </InputGroup>
            </FormControl>
        </GridItem>
        <GridItem colSpan={{base:12,md:6}}>
            <FormControl isRequired id="password">
                <FormLabel color="black">Password</FormLabel>
                <InputGroup>
                    <Input
                        type={show ? "text" : "password"}
                        name="password"
                        focusBorderColor="brand.500"
                        onChange={(e) => handleSetPassword(e.target.value)}
                    />
                    <InputRightElement>
                        <IconButton
                            aria-label={show ? "Hide password" : "Show password"}
                            icon={show ? (<ViewOffIcon color="brand.500"/>) : (<ViewIcon color="gray.300"/>)}
                            onClick={handleClick}
                            bg="transparent"
                            _hover={{bg: "trasparent"}}
                            _active={{bg: "transparent"}}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>
        </GridItem>
        <GridItem colSpan={{base:12,md:6}}>
            <FormControl isRequired id="password">
                <FormLabel color="black">Confirm Password</FormLabel>
                <InputGroup>
                    <Input
                        type="password"
                        name="password"
                        focusBorderColor="brand.500"
                        onChange={(e) => setConPassword(e.target.value)}
                    />
                </InputGroup>
            </FormControl>
        </GridItem>
    </>);
}

function Register() {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [role, setRole] = useState("user");
    const [userEmail, setUserEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [lowerCaps, setLowerCaps] = useState(false);
    const [upperCaps, setUpperCaps] = useState(false);
    const [digit, setDigit] = useState(false);
    const [isLength, setIsLength] = useState(false);

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [validPass, setValidPass] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const handleClick = () => setShow(!show);
    const navigate = useNavigate();

    const handleSetPassword = async (password) => {
        setPassword(password);
        let isValid = utils.validatePassword(password);
        setDigit(isValid.digit);
        setUpperCaps(isValid.uppercase);
        setLowerCaps(isValid.lowercase);
        setIsLength(isValid.isLength);
        setValidPass(isValid.isValid)

    };

    const handleRegister = async (event) => {
        try {
            event.preventDefault()
            if (firstname === "" || lastname === "" || phone === "" || conPassword === "" || password === "" || userEmail === "") {
                toast.closeAll();
                addGeneralToast({
                    message: "All fields must be filled in", type: "warning",
                });
                setLoading(false);
                return;
            }
            if (!validPass) {
                toast.closeAll();
                addGeneralToast({
                    message: "Password does not meet minimum requirements", type: "error",
                });
                return;
            }

            if (password !== conPassword) {
                toast.closeAll();
                addGeneralToast({
                    message: "Password does not match", type: "error",
                });
                return;
            }
            const data = {firstName: firstname, lastName: lastname, role, phone, password, email: userEmail}
            setLoading(true);
            const response = await API.post(
                `/auth/signup`,
                data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            setLoading(false);
            const result = await response.data
            if (response.status === 201) {
                localStorage.setItem('registerEmail',userEmail)
                navigate("/verify-account");
                // Handle the data
                toast.closeAll();
                addGeneralToast({
                    message: result.message, type: "success",
                });

            } else {

                toast.closeAll();
                addGeneralToast({
                    message: result.message, type: "error",
                });
            }
        } catch (e) {
            console.log(e)
            setLoading(false);
            toast.closeAll();
            addGeneralToast({
                message: e?.response ? e.response?.data?.message : "Error Occured!",
                type: "error",
            });
        }
    };

    return (<>

        <Grid templateColumns='repeat(12,1fr)'
              w={{base:"full",lg:"5xl"}}
              bg={"white"}
              gap={4}
              borderRadius={"md"}
              boxShadow={"md"}
              direction={{base: "column", md: "row"}}>
            <Hide below={'lg'}>
                <GridItem colSpan={5} bg={'brand.700'} borderRadius={'md'} py={4} pr={4} borderTopRightRadius="0"
                          borderBottomRightRadius="0">
                    <Flex direction={'column'}  justify={'center'} h='full' >
                        <Flex justifyContent={'center'} mb={8}>
                            <VStack>
                                <Box
                                    backgroundColor='white'
                                    borderRadius='50%'
                                    width='180px'
                                    height='180px'
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                >
                                    <Icon as={RiLockPasswordLine} color="brand.700" boxSize="50%" />
                                </Box>

                            </VStack>
                        </Flex>

                        <List textAlign={"start"} spacing={3}   bg={"white"} p={8} borderRightRadius={'2xl'}>
                            <ListItem>
                                <ListIcon
                                    as={isLength ? FaCheckCircle : FaTimesCircle}
                                    color={isLength ? "green.500" : "red.500"}
                                />
                                Password must contain atleast 8 characters
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={upperCaps ? FaCheckCircle : FaTimesCircle}
                                    color={upperCaps ? "green.500" : "red.500"}
                                />
                                Password must contain uppercase letter
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                                    color={lowerCaps ? "green.500" : "red.500"}
                                />
                                Password must contain lowercase letter
                            </ListItem>
                            <ListItem>
                                <ListIcon
                                    as={digit ? FaCheckCircle : FaTimesCircle}
                                    color={digit ? "green.500" : "red.500"}
                                />
                                Password must contain a digit
                            </ListItem>
                        </List>
                    </Flex>
                </GridItem>
            </Hide>
            <GridItem  colSpan={{base:12,lg:7}} p={{base:8}}>
                <Box  spacing={4} w={"full"}>
                    <Flex align={"center"} justify="start">
                        <Box mb={4}>

                            <Heading color="black" as="h6" size="lg">
                                Register
                            </Heading>
                            <Text color={"black"} mt={-1} fontSize={"lg"}>
                                Sign up to create a new account
                            </Text>
                        </Box>
                    </Flex>
                    <form onSubmit={handleRegister}>
                        <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                            <>
                                <GridItem colSpan={{base:12}}>
                                    <FormControl isRequired>
                                        <FormLabel color="black">Firstname</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type={"text"}
                                                name="firstName"
                                                focusBorderColor="brand.500"
                                                onChange={(e) => setFirstname(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={{base:12}}>
                                    <FormControl id="email" isRequired>
                                        <FormLabel color="black">Lastname</FormLabel>
                                        <InputGroup>
                                            <Input
                                                type={"text"}
                                                name="lastName"
                                                focusBorderColor="brand.500"
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </GridItem>
                            </>
                            <Credentials
                                setUserEmail={setUserEmail}
                                handleSetPassword={handleSetPassword}
                                setConPassword={setConPassword}
                                setPhone={setPhone}
                                show={show}
                                handleClick={handleClick}
                            />
                        </Grid>
                        <Stack spacing={6} mt={4}>
                            <Show below={'lg'}>
                                <List textAlign={"start"} spacing={3}   bg={"white"}  borderRightRadius={'2xl'}>
                                    <ListItem>
                                        <ListIcon
                                            as={isLength ? FaCheckCircle : FaTimesCircle}
                                            color={isLength ? "green.500" : "red.500"}
                                        />
                                        Password must contain atleast 8 characters
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={upperCaps ? FaCheckCircle : FaTimesCircle}
                                            color={upperCaps ? "green.500" : "red.500"}
                                        />
                                        Password must contain uppercase letter
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                                            color={lowerCaps ? "green.500" : "red.500"}
                                        />
                                        Password must contain lowercase letter
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={digit ? FaCheckCircle : FaTimesCircle}
                                            color={digit ? "green.500" : "red.500"}
                                        />
                                        Password must contain a digit
                                    </ListItem>
                                </List>
                            </Show>
                            {loading ? (<Button bg={"brand.500"} _hover={{bg: "black"}}>
                                <PropagateLoader size={8} color="white"/>
                            </Button>) : (<Button
                                _hover={{
                                    bg: "black", color: "white", borderColor: "black",
                                }}
                                color="white"
                                bg="brand.500"
                                type="submit"
                                variant="solid"
                            >
                                Register
                            </Button>)}
                            <HStack
                                justifyContent="center"
                                alignItems="center"
                                color="black"
                            >
                                <Text>I already have an Account</Text>
                                <RouterLink to="/login">
                                    <Link color="brand.500" _hover={{textDecoration: "none"}}>
                                        Login
                                    </Link>
                                </RouterLink>
                            </HStack>
                        </Stack>
                    </form>

                </Box>
            </GridItem>
        </Grid>


    </>);
}

export default Register;
