import React from "react";
import ResetPassowordComponent from "../ResetPassword/components/ResetPassword";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";

const ResetPassword = () => {
  return (
    <>
      <AuthenticationContainer Children={<ResetPassowordComponent />} />
    </>
  );
};

export default ResetPassword;
